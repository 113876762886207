import React from 'react';
import { fetchAnimalBreeds } from 'services/API/metadata';
import { Flex, Stack, Box, Checkbox, Spinner } from '@chakra-ui/core';
import { useQuery } from 'react-query';
import { groupBy, mapObjIndexed, values, fromPairs } from 'ramda';
import { useFormikContext, FastField } from 'formik';
import PropTypes from 'prop-types';

import Link from 'components/Link';
import Text from 'components/Text';
import {
  Errored,
  ErroredHeader,
  ErroredBody,
  ErroredRefreshButton,
} from 'components/Errored';
import CheckboxInput from 'components/CheckboxInput';

const AnimalBreedsCheckboxes = ({ fieldValues }) => {
  const { setFieldValue } = useFormikContext();
  const { data, isLoading, error } = useQuery(
    'species_breeds',
    fetchAnimalBreeds
  );

  if (isLoading) {
    return (
      <Flex justify="center" align="center">
        <Spinner color="primary.500" />
      </Flex>
    );
  } else if (error) {
    return (
      <Flex>
        <Errored>
          <ErroredHeader>Animal Breeds Error</ErroredHeader>
          <ErroredBody>
            There was an error while loading the animal breeds. Click refresh to
            try again.
          </ErroredBody>
          <ErroredRefreshButton refetchQueries={['species_breeds']} />
        </Errored>
      </Flex>
    );
  } else if (data) {
    const bySpecies = groupBy(speciesBreed => {
      return speciesBreed.species;
    }, data);

    return (
      <Flex direction="row" justify="space-between">
        {values(
          mapObjIndexed((breeds, currentSpecies) => {
            breeds = breeds.map(({ id, ...rest }) => ({
              id: id.toString(),
              ...rest,
            }));

            const allChecked = breeds.every(({ id }) => {
              return fieldValues[id];
            });
            const anyChecked = breeds.some(({ id }) => {
              return fieldValues[id];
            });

            return (
              <Flex mt="50px" direction="column" key={currentSpecies}>
                <Box mb="4">
                  <Text variant="formLabel">{currentSpecies + ' Breeds'}</Text>
                </Box>
                <Box mb="6">
                  <Checkbox
                    variantColor="primary"
                    size="lg"
                    isChecked={allChecked}
                    isIndeterminate={anyChecked && !allChecked}
                    onChange={() => {
                      const newValues = Object.assign(
                        fieldValues,
                        fromPairs(breeds.map(({ id }) => [id, !allChecked]))
                      );
                      setFieldValue('species_breeds', newValues);
                    }}
                  >
                    <Text variant="paragraph">All</Text>
                  </Checkbox>
                </Box>

                <Stack spacing={4}>
                  {breeds.map(speciesBreed => {
                    return (
                      <Box key={speciesBreed.id}>
                        <FastField name={`species_breeds.${speciesBreed.id}`}>
                          {({ field, form }) => {
                            return (
                              <Checkbox
                                variantColor="primary"
                                size="lg"
                                isChecked={field.value}
                                onChange={e =>
                                  form.setFieldValue(
                                    `species_breeds.${speciesBreed.id}`,
                                    e.target.checked
                                  )
                                }
                              >
                                <Text variant="paragraph">
                                  {speciesBreed.breed}
                                </Text>
                              </Checkbox>
                            );
                          }}
                        </FastField>
                      </Box>
                    );
                  })}
                </Stack>
              </Flex>
            );
          }, bySpecies)
        )}
      </Flex>
    );
  }
  return null;
};

AnimalBreedsCheckboxes.propTypes = {
  fieldValues: PropTypes.objectOf(PropTypes.bool),
};

export default AnimalBreedsCheckboxes;
