import React from 'react';
import PropTypes from 'prop-types';
import Text from 'components/Text';
import {
  AccordionItem,
  AccordionHeader,
  AccordionPanel,
} from '@chakra-ui/core';

const FormAccordionItem = ({ label, children, ...props }) => {
  return (
    <AccordionItem
      bg="white"
      rounded="sm"
      border="1px solid"
      borderColor="lightGray"
      mb={6}
      width="100%"
      {...props}
      defaultIsOpen={true}
    >
      <AccordionHeader _focus={{ borderColor: 'none' }}>
        <Text variant="formLabel">{label}</Text>
      </AccordionHeader>
      <AccordionPanel
        px="56px"
        pb={10}
        borderTop="1px solid"
        borderColor="lightGray"
      >
        {children}
      </AccordionPanel>
    </AccordionItem>
  );
};

FormAccordionItem.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node,
};

export default FormAccordionItem;
