import React from 'react';
import PropTypes from 'prop-types';
import { List, ListItem, Icon } from '@chakra-ui/core';
import { MdClose as CloseIcon, MdCheck as CheckIcon } from 'react-icons/md';

import TextInput from 'components/TextInput';
import Text from 'components/Text';
import { useFormikContext } from 'formik';

const renderValidationLine = (text, isChecked) => (
  <ListItem
    d="flex"
    alignItems="center"
    color={isChecked ? 'primary.500' : 'gray.400'}
    ml="4"
    aria-hidden={isChecked}
  >
    <Icon as={isChecked ? CheckIcon : CloseIcon} mr="2" />
    <Text variant="small">{text}</Text>
  </ListItem>
);

const NewPasswordFields = ({ passwordProps }) => {
  const { values } = useFormikContext();

  return (
    <>
      <TextInput
        name="password"
        label="Password"
        type="password"
        disableErrors
        {...passwordProps}
      />

      <TextInput
        name="password_confirmation"
        label="Confirm Password"
        type="password"
        disableErrors
      />

      <List alignSelf="flex-start">
        {renderValidationLine(
          'At least 8 characters',
          values.password.length >= 8
        )}
        {renderValidationLine(
          'At least one letter',
          /[a-z]/i.test(values.password)
        )}
        {renderValidationLine(
          'At least one number',
          /[0-9]/.test(values.password)
        )}
        {renderValidationLine(
          'Passwords must match',
          values.password && values.password === values.password_confirmation
        )}
      </List>
    </>
  );
};
NewPasswordFields.propTypes = {
  passwordProps: PropTypes.object,
};

export default NewPasswordFields;
