import React from 'react';
import 'react-dates/initialize';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { useMutation } from 'react-query';
import { Flex, Box, Accordion, useDisclosure, useToast } from '@chakra-ui/core';

import { phoneNumberValidator } from 'Utilities/validators';

import Text from 'components/Text';
import Button from 'components/Button';
import TextInput from 'components/TextInput';
import DateRangeInput, { dateRangeSchema } from 'components/DateRangeInput';
import StateSelectInput from 'components/StateSelectInput';
import FormAccordionItem from 'components/FormAccordionItem';
import Divider from 'components/Divider';
import ConfirmationModal from 'components/ConfirmationModal';
import CancellationModal from 'components/CancellationModal';

import { requestShow } from 'services/API/shows';

const RequestShowValidationSchema = Yup.object().shape({
  show_name: Yup.string()
    .max(30, 'Must not exceed 30 characters.')
    .required('No show name provided.'),
  estimated_entries: Yup.number().required(
    'Estimated number of entries required.'
  ),
  contact_first_name: Yup.string()
    .max(15, 'Must not exceed 15 characters.')
    .required('No first name provided.'),
  contact_last_name: Yup.string()
    .max(15, 'Must not exceed 15 characters.')
    .required('No last name provided.'),
  contact_phone_number: phoneNumberValidator.required(
    'No phone number provided.'
  ),
  contact_email: Yup.string()
    .email('Invalid email address.')
    .required('No email provided.'),
  facility_name: Yup.string()
    .max(50, 'Must not exceed 50 characters.')
    .required('No location or facility name provided.'),
  facility_city: Yup.string()
    .max(30, 'Must not exceed 50 characters.')
    .required('No city provided.'),
  facility_state: Yup.object().required('No state provided.'),
  show_dates: dateRangeSchema,
});

const RequestAShow = () => {
  const toast = useToast();
  const confirmationModal = useDisclosure();
  const cancellationModal = useDisclosure();
  const [mutate, { data: newShow }] = useMutation(requestShow);

  console.log(newShow);

  return (
    <>
      <ConfirmationModal
        title={'Thank you for your Request'}
        body={
          'You will receive a confirmation email when your requested show has been approved. This show will not be visible online until admin approval.'
        }
        buttonText={'okay'}
        buttonColor={'primary'}
        isOpen={confirmationModal.isOpen}
        onClose={confirmationModal.onClose}
        redirect={newShow ? `/shows/${newShow.id}` : '/shows'}
      />
      <CancellationModal
        title={'Cancel Show Request'}
        body={'Are you sure? If you cancel this request it cannot be undone.'}
        buttonText={'cancel request'}
        buttonColor={'red'}
        isOpen={cancellationModal.isOpen}
        onClose={cancellationModal.onClose}
        redirect="/shows"
      />
      <Box width="100vw" bg="white" height="56px"></Box>
      <Flex
        minHeight="100vh"
        width="100vw"
        bg="gray.50"
        direction="column"
        alignItems="center"
      >
        <Flex direction="column" mt="20px">
          <Flex justify="space-between">
            <Flex>
              <Text variant="h1">Request Show</Text>
            </Flex>
            <Flex alignSelf="flex-end">
              <Button
                variant="link"
                fontSize="md"
                size="sm"
                variantColor="red"
                color="red.400"
                textDecoration="none"
                px="5px"
                onClick={cancellationModal.onOpen}
              >
                Cancel Request
              </Button>
            </Flex>
          </Flex>

          <Divider />
          <Formik
            initialValues={{
              show_name: '',
              estimated_entries: '',
              contact_first_name: '',
              contact_last_name: '',
              contact_phone_number: '',
              contact_email: '',
              facility_name: '',
              facility_city: '',
              facility_state: '',
              show_dates: { startDate: null, endDate: null },
            }}
            validationSchema={RequestShowValidationSchema}
            onSubmit={async (values, { setSubmitting, setErrors }) => {
              try {
                await mutate(values);
                confirmationModal.onOpen();
              } catch (e) {
                // sets the formik error messages to the ones provided from the response
                if (e.status === 422) {
                  setErrors(e.error.validation_messages);
                } else {
                  toast({
                    title: 'Something Went Wrong!',
                    description: 'Please try again later.',
                    status: 'error',
                    duration: 8000,
                    isClosable: true,
                  });
                }
              } finally {
                setSubmitting(false);
              }
            }}
          >
            {({ handleSubmit, isValid, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <Accordion index={[0, 1, 2, 3]} allowMultiple width="750px">
                  <FormAccordionItem label="Primary Contact">
                    <Flex>
                      <TextInput
                        width="100%"
                        name="contact_first_name"
                        label="First Name"
                      />
                      <TextInput
                        width="100%"
                        name="contact_last_name"
                        label="Last Name"
                      />
                    </Flex>

                    <TextInput name="contact_phone_number" label="Phone" />
                    <TextInput name="contact_email" label="Email" />
                  </FormAccordionItem>
                  <FormAccordionItem label="Dates & Registration">
                    <DateRangeInput name="show_dates" label="Show Dates" />
                  </FormAccordionItem>
                  <FormAccordionItem label="Show Details">
                    <TextInput
                      name="show_name"
                      label="Show Name"
                      helperText="This cannot be edited after submission."
                    />
                    <TextInput
                      type="number"
                      name="estimated_entries"
                      label="Estimated Number of Entries"
                    />
                  </FormAccordionItem>
                  <FormAccordionItem label="Location">
                    <TextInput name="facility_name" label="Facility Name" />
                    <Flex>
                      <TextInput name="facility_city" label="City" />
                      <StateSelectInput
                        name="facility_state"
                        label="State"
                        width="110px"
                      />
                    </Flex>
                  </FormAccordionItem>
                </Accordion>
                <Flex justify="flex-end" mb="40px">
                  <Button
                    aria-label="Submit show request"
                    variantColor="primary"
                    isDisabled={!isValid}
                    isLoading={isSubmitting}
                    type="submit"
                  >
                    Request Show
                  </Button>
                </Flex>
              </Form>
            )}
          </Formik>
        </Flex>
      </Flex>
    </>
  );
};
export default RequestAShow;
