import React, { useState } from 'react';
import { Field } from 'formik';
import { DateRangePicker } from 'react-dates';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import moment from 'moment';
import { Box } from '@chakra-ui/core';

import FieldWrapper from 'components/FieldWrapper';

const Wrapper = styled(Box)(
  css({
    '.DateInput_input': {
      fontSize: 'md',
    },
  })
);

export const dateRangeSchema = Yup.mixed().test({
  name: 'dateRangeHasDates',
  exclusive: true,
  message: 'Start and end dates are required',
  test: value => {
    return value.startDate && value.endDate;
  },
});

const DateRangeInput = props => {
  const [focusedInput, setFocusedInput] = useState(null);

  return (
    <Field {...props}>
      {({ field, form }) => {
        return (
          <FieldWrapper
            meta={{
              touched: form.touched[props.name],
              error: form.errors[props.name],
            }}
            label={props.label}
          >
            <Wrapper>
              <DateRangePicker
                startDate={field.value.startDate}
                startDateId={'start-' + field.name}
                endDate={field.value.endDate}
                endDateId={'end-' + field.name}
                onDatesChange={newValue => {
                  form.setFieldValue(field.name, newValue);
                }}
                focusedInput={focusedInput}
                onFocusChange={newFocusedInput => {
                  setFocusedInput(newFocusedInput);
                  if (newFocusedInput === null) {
                    setTimeout(() => {
                      form.setFieldTouched(field.name);
                    }, 0);
                  }
                }}
              />
            </Wrapper>
          </FieldWrapper>
        );
      }}
    </Field>
  );
};

DateRangeInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
};

export default DateRangeInput;
