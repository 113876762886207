import React from 'react';
import PropTypes, { oneOfType } from 'prop-types';
import { Flex } from '@chakra-ui/core';
import { refetchAllQueries, useIsFetching, refetchQuery } from 'react-query';

import Text from 'components/Text';
import Button from 'components/Button';

export const ErroredHeader = ({ large, ...props }) => {
  return (
    <Text
      variant={large ? 'h1' : 'formLabel'}
      color="red.400"
      mb={large ? '6' : '2'}
      {...props}
    />
  );
};
ErroredHeader.propTypes = {
  large: PropTypes.bool,
};

export const ErroredBody = props => {
  return <Text variant="paragraph" {...props} />;
};

export const ErroredRefreshButton = ({ refetchQueries, ...props }) => {
  const isFetching = useIsFetching();

  const handleRefresh = () => {
    if (refetchQueries) {
      refetchQueries.forEach(refetchQuery);
    } else {
      refetchAllQueries();
    }
  };

  return (
    <Button
      variantColor="red"
      onClick={handleRefresh}
      mt="8"
      isLoading={isFetching}
      {...props}
    >
      refresh
    </Button>
  );
};
ErroredRefreshButton.propTypes = {
  refetchQueries: PropTypes.arrayOf(
    // Must be a valid react-query cache key
    PropTypes.oneOfType([PropTypes.string, PropTypes.array])
  ),
};

export const Errored = ({ children, ...props }) => {
  return (
    <Flex
      direction="column"
      height="100%"
      minHeight="300px"
      justify="center"
      align="center"
      {...props}
    >
      {children}
    </Flex>
  );
};
Errored.propTypes = {
  children: PropTypes.node,
};
