import React from 'react';
import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';
import { MdAttachFile as AttachFile } from 'react-icons/md';
import {
  VisuallyHidden,
  InputGroup,
  Flex,
  Input,
  InputRightElement,
  Icon,
  IconButton,
} from '@chakra-ui/core';

import FieldWrapper from 'components/FieldWrapper';
import Text from 'components/Text';

const FileUploadInput = ({
  label,
  accept,
  containerProps,
  helperText,
  ...props
}) => {
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();

  const handleChange = e => {
    setFieldValue(field.name, e.target.files[0]);
  };

  return (
    <FieldWrapper
      meta={meta}
      label={label}
      containerProps={containerProps}
      helperText={helperText}
    >
      <VisuallyHidden>
        <input
          {...field}
          value=""
          type="file"
          accept={accept}
          onChange={handleChange}
        />
      </VisuallyHidden>
      <InputGroup>
        <Input
          as="div"
          isInvalid={!!(meta.touched && meta.error)}
          focusBorderColor="primary.500"
          errorBorderColor="red.400"
        >
          <Text variant="paragraph" fontWeight="normal">
            {field.value && field.value.name}
          </Text>
        </Input>
        <InputRightElement>
          <Flex>
            {field.value ? (
              <IconButton
                icon="close"
                variant="ghost"
                aria-label={`Clear ${label}`}
                onClick={e => {
                  e.preventDefault();
                  setFieldValue(field.name, null);
                }}
              />
            ) : (
              <Icon as={AttachFile} fontSize="24px" color="primary.400" />
            )}
          </Flex>
        </InputRightElement>
      </InputGroup>
    </FieldWrapper>
  );
};

FileUploadInput.propTypes = {
  label: PropTypes.node,
  accept: PropTypes.string,
  containerProps: PropTypes.object,
  helperText: PropTypes.string,
};

export default FileUploadInput;
