import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Textarea, Box } from '@chakra-ui/core';
import { useField } from 'formik';

import Text from 'components/Text';
import FieldWrapper from 'components/FieldWrapper';

export const TextAreaInput = ({ max, helperText, label, ...props }) => {
  const [field, meta] = useField(props.name);

  return (
    <FieldWrapper meta={meta} label={label} helperText={helperText}>
      <Box position="relative">
        <Textarea
          maxLength={max}
          focusBorderColor="primary.500"
          errorBorderColor="red.400"
          {...field}
          {...props}
        />
        <Text
          variant="small"
          color="darkGray"
          position="absolute"
          right={3}
          bottom={1}
        >{`${field.value.length} / ${max}`}</Text>
      </Box>
    </FieldWrapper>
  );
};

TextAreaInput.propTypes = {
  max: PropTypes.number,
  helperText: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.node,
};

export default TextAreaInput;
