/**
 * @param {number} i - the number to format
 *
 * @example ordinalSuffix(1) === "1st"
 * @example ordinalSuffix(2) === "2nd"
 * @example ordinalSuffix(3) === "3rd"
 * @example ordinalSuffix(100) === "100th"
 */
function ordinalSuffix(i) {
  const j = i % 10,
    k = i % 100;
  if (j === 1 && k !== 11) {
    return i + 'st';
  }
  if (j === 2 && k !== 12) {
    return i + 'nd';
  }
  if (j === 3 && k !== 13) {
    return i + 'rd';
  }
  return i + 'th';
}

export default ordinalSuffix;
