import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  isNil,
  omit,
  find,
  uniqBy,
  pipe,
  defaultTo,
  map,
  move,
  remove,
  insert,
  sortBy,
  prop,
} from 'ramda';
import {
  Flex,
  PseudoBox,
  Box,
  Icon,
  Link,
  Divider,
  FormErrorMessage,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogBody,
  AlertDialogFooter,
  useDisclosure,
  Alert,
  AlertTitle,
  AlertDescription,
  AlertIcon,
} from '@chakra-ui/core';
import { Formik, Form, useFormikContext } from 'formik';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useQuery, useMutation } from 'react-query';
import { useParams, Link as RLink } from 'react-router-dom';
import * as yup from 'yup';

import {
  MdPlaylistAddCheck as CompleteIcon,
  MdAddCircle as PlusIcon,
} from 'react-icons/md';

import Page from 'components/Page';
import PageHeader from 'components/PageHeader';
import {
  SectionCard,
  SectionHeader,
  SectionContent,
} from 'components/SectionCard';
import Button from 'components/Button';
import Text from 'components/Text';
import SelectInput from 'components/SelectInput';

import ordinalSuffix from 'Utilities/ordinalSuffix';
import {
  getCompetitionGroupById,
  getCompetitionGroups,
  placeCompetitionGroup,
  setClassStatus,
  fetchShowById,
} from 'services/API/shows';
import useLockBodyScroll from 'Hooks/useLockBodyScroll';
import useToast from 'Hooks/useToast';
import {
  Errored,
  ErroredHeader,
  ErroredBody,
  ErroredRefreshButton,
} from 'components/Errored';

const ConfirmModal = props => {
  const { showId } = useParams();
  const toast = useToast();

  const [placeGroup, { error }] = useMutation(placeCompetitionGroup, {
    refetchQueries: ['competitionGroups', { showId }],
  });

  const {
    values,
    setSubmitting,
    setFieldValue,
    isSubmitting,
  } = useFormikContext();
  const { buckets, advancementDestination, selectedCompGroup } = values;

  const handleSubmit = async () => {
    setSubmitting(true);
    const placements = [
      ...buckets.advancing.map((animal, index) => ({
        competitor_id: animal.competitor_id,
        place: index + 1,
        advance_comp_group_id:
          typeof advancementDestination === 'object'
            ? advancementDestination.value
            : advancementDestination,
      })),
      ...buckets.placed.map((animal, index) => ({
        competitor_id: animal.competitor_id,
        place: buckets.advancing.length + index + 1,
        advance_comp_group_id: null,
      })),
      ...buckets.didNotPlace.map((animal, index) => ({
        competitor_id: animal.competitor_id,
        place: null,
        advance_comp_group_id: null,
      })),
    ];
    try {
      await placeGroup({
        placements,
        showId,
        competitionGroupId: selectedCompGroup,
      });

      // Close the current comp group
      setFieldValue('selectedCompGroup', null);
      props.onClose();
      toast({
        title: 'Ring Placed!',
        description:
          'This class has been placed and advancing animals have been notified. Results have been published. ',
        status: 'success',
        isClosable: true,
      });
    } catch (e) {
      // Errors are rendered via the useMutation hook above
      // Just logging here for developer awareness
      console.error(e);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <AlertDialog {...props}>
      <AlertDialogOverlay />
      <AlertDialogContent p="8" maxW="2xl">
        <AlertDialogHeader>
          <Text variant="h1">Confirm to Place Class</Text>
        </AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          {error ? (
            <Text variant="paragraph" color="red.400">
              An error occurred while submitting the class placement.
            </Text>
          ) : (
            <Text variant="paragraph">
              Your confirmation is needed. To continue placing this class,
              please click submit so advancing animals can be notified and the
              results can be posted.
            </Text>
          )}
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button
            type="button"
            variantColor={error ? 'red' : 'primary'}
            onClick={handleSubmit}
            isLoading={isSubmitting}
          >
            {error ? 'try again' : 'submit'}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
ConfirmModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

const AnimalCard = ({
  animal: { id, full_registry_name, bib_number, place, showman_name },
  index,
  isDisabled,
}) => {
  return (
    <Draggable draggableId={id} index={index} isDragDisabled={isDisabled}>
      {provided => {
        return (
          <Flex
            cursor={isDisabled ? 'not-allowed' : 'pointer'}
            align="center"
            mb="2"
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            aria-label={bib_number}
          >
            {!isNil(place) && (
              <Text variant="paragraph" color="gray.400" width="40px">
                {ordinalSuffix(place)}
              </Text>
            )}
            <Box
              bg="white"
              shadow="sm"
              p="4"
              border="1px"
              borderColor="gray.200"
              flex="1"
            >
              <Text variant="paragraph">{bib_number}</Text>
              <Text variant="small" color="gray.400">
                {full_registry_name}
              </Text>
              <Text color="gray.400" variant="small">
                {showman_name}
              </Text>
            </Box>
          </Flex>
        );
      }}
    </Draggable>
  );
};
AnimalCard.propTypes = {
  animal: PropTypes.shape({
    id: PropTypes.string,
    full_registry_name: PropTypes.string,
    nickname: PropTypes.string,
    bib_number: PropTypes.number,
    place: PropTypes.number,
    showman_name: PropTypes.string,
  }),
  index: PropTypes.number,
  isDisabled: PropTypes.bool,
};

const Bucket = ({
  placeholderText,
  name,
  animals,
  droppableId,
  placeholder,
  isDisabled,
  ...props
}) => {
  return (
    <Droppable droppableId={droppableId} {...props}>
      {(provided, snapshot) => {
        const isOver = snapshot.isDraggingOver;

        return (
          <Box
            bg={
              snapshot.draggingFromThisWith || isOver
                ? 'gray.50'
                : 'transparent'
            }
          >
            {name && (
              <Box bg="white" p="4" pb="0">
                <Text variant="formLabel">{name}</Text>
                <Divider mb="0" />
              </Box>
            )}
            <Box
              aria-label={name}
              transition="opacity 0.2s"
              p="4"
              pb="6"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {animals.length === 0 &&
                (placeholder || (
                  <Box>
                    <Flex
                      color="gray.400"
                      direction="column"
                      align="center"
                      textAlign="center"
                      p={4}
                    >
                      <Icon
                        as={PlusIcon}
                        color="gray.200"
                        fontSize="42px"
                        mb="2"
                      />
                      <Text variant="callout">drag animal cards here</Text>
                      <Text variant="small">{placeholderText}</Text>
                    </Flex>
                  </Box>
                ))}
              {sortBy(prop('index'), animals).map((animal, index) => {
                return (
                  <AnimalCard
                    key={animal.id}
                    animal={animal}
                    index={index}
                    isDisabled={isDisabled}
                  />
                );
              })}
              {animals.length === 0 ? (
                <Box display={'none'}>{provided.placeholder}</Box>
              ) : (
                provided.placeholder
              )}
            </Box>
          </Box>
        );
      }}
    </Droppable>
  );
};
Bucket.propTypes = {
  name: PropTypes.string,
  animals: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      place: PropTypes.number,
    })
  ),
  isDisabled: PropTypes.bool,
  placeholderText: PropTypes.string,
  droppableId: PropTypes.string,
  placeholder: PropTypes.node,
};

const Columns = ({
  groups,
  advancementDestinationOptions,
  isShowPublished,
}) => {
  const {
    isSubmitting,
    isValid,
    values,
    setFieldValue,
    submitCount,
    errors,
    resetForm,
  } = useFormikContext();
  const toast = useToast();

  const { showId } = useParams();

  const buckets = values.buckets;

  const setBuckets = useCallback(value => setFieldValue('buckets', value), [
    setFieldValue,
  ]);
  const [isInTheRing, setIsInTheRing] = useState(false);
  const [isErrored, setIsErrored] = useState(false);
  // Make sure that the currently selected comp group is displayed in the sidebar
  const selectedCompGroup =
    groups && find(({ id }) => values.selectedCompGroup === `${id}`, groups)
      ? values.selectedCompGroup
      : null; // If it's not, then just act like nothing is selected

  const [
    setClassStatusMutation,
    { isLoading: isSettingClassStatus },
  ] = useMutation(setClassStatus, {
    refetchQueries: [['competitionGroups', { showId }]],
  });

  const handleSetClassStatus = async isInTheRing => {
    await setClassStatusMutation({
      showId,
      competitionGroupId: selectedCompGroup,
      isInTheRing,
    })
      .then(() => {
        setIsInTheRing(currentValue => !currentValue);
      })
      .catch(e => {
        if (e.status === 409) {
          toast({
            title: 'Please Place or Stop Current Class',
            description:
              'You can only have one class in a ring at one time. Please place or stop existing class before starting a new class.',
            status: 'error',
            isClosable: true,
          });
        } else {
          toast({
            title: 'An Unknown Error Occurred',
            description: 'Please refresh the page to try again.',
            status: 'error',
            isClosable: true,
          });
        }
      });
  };

  const setSelectedCompGroup = value => {
    setFieldValue('selectedCompGroup', value);
    setFieldValue('advancementDestination', null);
  };

  const loadBuckets = () => {
    setBuckets(null);
    setIsInTheRing(false);
    setIsErrored(false);

    if (!isNil(selectedCompGroup)) {
      getCompetitionGroupById({
        showId,
        competitionGroupId: selectedCompGroup,
      })
        .then(data => {
          const competitors = data.competitors.map(({ id, ...rest }) => ({
            ...rest,
            id: `${id}`,
          }));

          setIsInTheRing(data.in_the_ring);

          if (data.placed) {
            const advancing = competitors.filter(
              ({ advance_comp_group_id }) => advance_comp_group_id
            );
            const placed = competitors.filter(
              ({ advance_comp_group_id, place }) =>
                place && !advance_comp_group_id
            );
            const didNotPlace = competitors.filter(
              ({ advance_comp_group_id, place }) =>
                !place && !advance_comp_group_id
            );

            resetForm({
              values: {
                ...values,
                buckets: {
                  notPlaced: [],
                  advancing,
                  placed,
                  didNotPlace,
                },
                advancementDestination:
                  advancing.length >= 1
                    ? advancing[0].advance_comp_group_id
                    : '',
              },
            });
          } else {
            resetForm({
              values: {
                ...values,
                buckets: {
                  notPlaced: competitors,
                  advancing: [],
                  placed: [],
                  didNotPlace: [],
                },
              },
            });
          }
        })
        .catch(e => {
          console.log(e);
          setIsErrored(true);
        });
    }
  };

  useEffect(() => {
    loadBuckets();
  }, [selectedCompGroup, setBuckets, showId]);

  const handleDragEnd = ({ destination, source }) => {
    if (!destination) return;
    if (source.droppableId === destination.droppableId) {
      setBuckets({
        ...buckets,
        [destination.droppableId]: move(
          source.index,
          destination.index,
          buckets[destination.droppableId]
        ),
      });
    } else {
      setBuckets({
        ...buckets,
        [source.droppableId]: remove(
          source.index,
          1,
          buckets[source.droppableId]
        ),
        [destination.droppableId]: insert(
          destination.index,
          buckets[source.droppableId][source.index],
          buckets[destination.droppableId]
        ),
      });
    }
  };

  return (
    <Flex w="100%" height="100%" minHeight="lg">
      <DragDropContext onDragEnd={handleDragEnd}>
        <SectionCard flex="1">
          <SectionHeader>Class</SectionHeader>
          <SectionContent bg="gray.50" p="0" overflowY="auto">
            {(!groups || groups.length === 0) && (
              <Text
                pt="16"
                textAlign="center"
                variant="paragraph"
                color="gray.400"
              >
                Select a ring to begin
              </Text>
            )}
            {groups &&
              groups.map(({ id, placed, name, animal_count, in_the_ring }) => {
                const isSelected = `${id}` === selectedCompGroup;

                return (
                  <PseudoBox
                    role="button"
                    key={id}
                    py="3"
                    px="4"
                    borderBottom="1px"
                    borderBottomColor="gray.200"
                    borderLeft="12px solid"
                    borderLeftColor={isSelected ? 'primary.500' : 'transparent'}
                    transition="border-left-color 0.2s"
                    bg={isSelected ? 'white' : 'transparent'}
                    _hover={{
                      bg: 'white',
                    }}
                    onClick={() => setSelectedCompGroup(`${id}`)}
                  >
                    <Flex>
                      {placed && (
                        <Icon mr="2" as={CompleteIcon} color="green.500" />
                      )}
                      <Text variant="formLabel">{name}</Text>
                    </Flex>
                    <Flex align="baseline">
                      {in_the_ring && (
                        <Text variant="small" color="primary.500">
                          In The Ring -&nbsp;
                        </Text>
                      )}
                      <Text variant="small" color="gray.400">
                        {animal_count} Animals
                      </Text>
                    </Flex>
                  </PseudoBox>
                );
              })}
          </SectionContent>
        </SectionCard>

        <SectionCard
          flex="1"
          isLoading={selectedCompGroup && !buckets && !isErrored}
          mx="-1px"
        >
          <SectionHeader>Animals</SectionHeader>
          <SectionContent p="0">
            {isErrored ? (
              <Errored height="auto">
                <ErroredHeader>Failed to load this class.</ErroredHeader>
                <ErroredBody>Please refresh to try again.</ErroredBody>
                <ErroredRefreshButton
                  onClick={loadBuckets}
                  refetchQueries={[]}
                />
              </Errored>
            ) : (
              buckets && (
                <>
                  <Box p="4" borderBottom="1px" borderColor="gray.200">
                    <Button
                      w="100%"
                      variantColor={isInTheRing ? 'red' : 'primary'}
                      onClick={() => handleSetClassStatus(!isInTheRing)}
                      isLoading={isSettingClassStatus}
                      isDisabled={!isShowPublished}
                    >
                      {isInTheRing ? 'Stop Class' : 'Start Class'}
                    </Button>
                  </Box>
                  <Bucket
                    droppableId="notPlaced"
                    isDropDisabled={true}
                    isDisabled={!isShowPublished || !isInTheRing}
                    placeholder={
                      <Text
                        variant="paragraph"
                        color="gray.400"
                        textAlign="center"
                        mt="10"
                      >
                        All animals placed
                      </Text>
                    }
                    animals={buckets.notPlaced}
                  />
                  <FormErrorMessage
                    isInvalid={!!errors.buckets && submitCount >= 1}
                    px="3"
                    pl="5"
                  >
                    <Text variant="paragraph">{errors.buckets}</Text>
                  </FormErrorMessage>
                </>
              )
            )}
          </SectionContent>
        </SectionCard>

        <SectionCard flex="1">
          <SectionHeader>Place</SectionHeader>
          <SectionContent p="0" height="100%">
            {buckets && (
              <>
                <Flex direction="column" height="100%">
                  <Box p="4">
                    <SelectInput
                      isClearable
                      placeholder={null}
                      containerProps={{ mt: 0 }}
                      label="Advancement Destination"
                      name="advancementDestination"
                      options={advancementDestinationOptions}
                    />
                  </Box>

                  <Box
                    borderBottom="1px"
                    borderTop="1px"
                    borderColor="gray.200"
                    flex="1"
                  >
                    <Bucket
                      droppableId="advancing"
                      name="Advancing Animals"
                      animals={buckets.advancing.map((animal, index) => ({
                        ...animal,
                        place: index + 1,
                      }))}
                      placeholderText="These animals will advance to the next competition group."
                    />

                    <Bucket
                      name="Non-Advancing Animals"
                      droppableId="placed"
                      animals={buckets.placed.map((animal, index) => ({
                        ...animal,
                        place: buckets.advancing.length + index + 1,
                      }))}
                      placeholderText="These animals will be placed within the group, and will not be advanced to the next competition group."
                    />

                    <Bucket
                      name="Did Not Place"
                      droppableId="didNotPlace"
                      animals={buckets.didNotPlace.map(omit(['place']))}
                      placeholderText="These animals will not be displayed in show results in the the Ringside app."
                    />
                  </Box>

                  <Box p="4" justifySelf="stretch" mt="auto">
                    {submitCount >= 1 && !isValid && (
                      <Text variant="paragraph" color="red.400" mb="3">
                        Errors exist above
                      </Text>
                    )}
                    <Flex>
                      <Button
                        w="100%"
                        variant="ghost"
                        variantColor="primary"
                        mr="4"
                        onClick={() => setSelectedCompGroup(null)}
                      >
                        Cancel
                      </Button>

                      <Button
                        type="submit"
                        w="100%"
                        variantColor="primary"
                        isDisabled={submitCount >= 1 && !isValid}
                        isLoading={isSubmitting}
                      >
                        Submit
                      </Button>
                    </Flex>
                  </Box>
                </Flex>
              </>
            )}
          </SectionContent>
        </SectionCard>
      </DragDropContext>
    </Flex>
  );
};
Columns.propTypes = {
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      placed: PropTypes.bool,
      name: PropTypes.string,
      animal_count: PropTypes.number,
    })
  ),
  advancementDestinationOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    })
  ),
  isShowPublished: PropTypes.bool,
};

const Placement = () => {
  const { showId } = useParams();
  useLockBodyScroll();

  // Fetch all the competition groups
  const groupsResponse = useQuery(
    ['competitionGroups', { showId }],
    getCompetitionGroups
  );

  // Fetch current show data
  const showResponse = useQuery(['shows', { id: showId }], fetchShowById);
  const isShowPublished =
    showResponse.data && showResponse.data.status === 'published';

  const ringOptions = pipe(
    defaultTo([]),
    map(prop('ring')),
    uniqBy(prop('id')),
    map(({ name, id }) => ({ label: name, value: id }))
  )(groupsResponse.data);

  const modalState = useDisclosure();

  return (
    <Formik
      validateOnMount={false}
      initialValues={{
        advancementDestination: '',
        ring: null,
        selectedCompGroup: null,
      }}
      validationSchema={yup.object().shape({
        advancementDestination: yup
          .string()
          .nullable()
          .when('buckets', (buckets, schema) => {
            if (!buckets) return schema;
            const advancingAnimals = buckets.advancing;

            if (advancingAnimals.length > 0) {
              return schema.required(
                `Required when animals exist in 'Advancing Animals'`
              );
            }
            return schema;
          }),
        buckets: yup
          .object()
          .test('placement-complete', 'All animals must be placed', buckets => {
            return buckets.notPlaced.length === 0;
          }),
      })}
      onSubmit={(_values, { setSubmitting }) => {
        modalState.onOpen();
        setSubmitting(false);
      }}
    >
      {({ values }) => {
        return (
          <Page isLoading={groupsResponse.isLoading}>
            <Flex direction="column">
              <PageHeader>Place Classes</PageHeader>

              {!groupsResponse.error && (
                <SelectInput
                  placeholder={null}
                  name="ring"
                  label="Select Ring"
                  containerProps={{ maxW: '2xs', mb: '4' }}
                  options={ringOptions}
                />
              )}

              {!isShowPublished && (
                <Alert
                  status="warning"
                  variant="left-accent"
                  mb="4"
                  maxWidth="500px"
                  variantColor="yellow"
                  bg="yellow.50"
                  borderColor="yellow.300"
                >
                  <AlertIcon color="yellow.300" />
                  <Box>
                    <AlertTitle mb="1">
                      <Text variant="formLabel">
                        Classes cannot be placed until the show is published
                      </Text>
                    </AlertTitle>
                    <AlertDescription>
                      <Text variant="paragraph" lineHeight="shorter">
                        <Link
                          as={RLink}
                          to={`/shows/${showId}/edit`}
                          color="blue.500"
                        >
                          Edit this show
                        </Link>{' '}
                        and click &quot;Save and Publish&quot; to publish it.
                      </Text>
                    </AlertDescription>
                  </Box>
                </Alert>
              )}

              <Box as={Form}>
                <ConfirmModal
                  isOpen={modalState.isOpen}
                  onClose={modalState.onClose}
                />
                {groupsResponse.error ? (
                  <Errored bg="white">
                    <ErroredHeader large>Class Loading Error</ErroredHeader>
                    <ErroredBody textAlign="center">
                      There was an error in loading this list of classes.
                      <br />
                      Refresh to try again.
                    </ErroredBody>
                    <ErroredRefreshButton
                      refetchQueries={[['competitionGroups', { showId }]]}
                    />
                  </Errored>
                ) : (
                  <Columns
                    groups={
                      groupsResponse.data &&
                      groupsResponse.data.filter(({ ring: { id } }) => {
                        if (!values.ring) return false;
                        return id === values.ring.value;
                      })
                    }
                    advancementDestinationOptions={
                      groupsResponse.data &&
                      groupsResponse.data
                        .filter(({ id, placed, ring }) => {
                          // Remove placed classes
                          if (placed) return false;
                          // Remove currently selected comp group
                          if (values.selectedCompGroup === `${id}`)
                            return false;
                          // Remove all classes from other rings
                          return (values.ring && values.ring.value) === ring.id;
                        })
                        .map(({ id, name }) => ({
                          label: name,
                          value: id,
                        }))
                    }
                    isShowPublished={isShowPublished}
                  />
                )}
              </Box>
            </Flex>
          </Page>
        );
      }}
    </Formik>
  );
};

export default Placement;
