import API from './API';
import { reject, isNil, mapObjIndexed } from 'ramda';
import moment from 'moment';
import { formatResponse, toFormData } from './utils';

export const getShowAdminsById = ({ showId }) => {
  return formatResponse(API.get(`/shows/${showId}/admins`));
};

export const getCompetitionGroups = ({ showId }) => {
  return formatResponse(API.get(`/shows/${showId}/competition_groups`));
};

export const getCompetitionGroupById = ({ showId, competitionGroupId }) => {
  return formatResponse(
    API.get(`/shows/${showId}/competition_groups/${competitionGroupId}`)
  );
};

export const placeCompetitionGroup = ({
  showId,
  competitionGroupId,
  placements,
}) => {
  return formatResponse(
    API.post(
      `/shows/${showId}/competition_groups/${competitionGroupId}/place`,
      { placements }
    )
  );
};

export const getShows = async () => {
  return formatResponse(API.get('/shows/manage_list'));
};

export const deleteShowAdmin = ({ showId, userId }) => {
  return formatResponse(
    API.delete(`/shows/${showId}/admins`, { data: { show_admins: [userId] } })
  );
};

export const addShowAdmin = ({ showId, userId }) => {
  return formatResponse(
    API.post(`/shows/${showId}/admins`, { show_admins: [userId] })
  );
};

const apiDateFormat = 'YYYY-MM-DD';

export const requestShow = async ({
  show_name,
  estimated_entries,
  show_dates,
  contact_first_name,
  contact_last_name,
  contact_email,
  contact_phone_number,
  facility_name,
  facility_city,
  facility_state,
}) => {
  return formatResponse(
    API.post('/shows', {
      show_name,
      estimated_entries,
      show_start_date: show_dates.startDate.format(apiDateFormat),
      show_end_date: show_dates.endDate.format(apiDateFormat),
      contact_first_name,
      contact_last_name,
      contact_email,
      // Remove all non-digits
      contact_phone_number: contact_phone_number.replace(/\D/g, ''),
      facility_name,
      facility_city,
      facility_state: facility_state.abbreviation,
    })
  );
};

export const fetchShowById = async ({ id }) => {
  const data = await formatResponse(API.get(`/shows/${id}`));

  // Show dates
  data.show_start_date = moment(data.show_start_date);
  data.show_end_date = moment(data.show_end_date);

  // Registration dates
  if (
    data.registration_start_date !== null &&
    data.registration_end_date !== null
  ) {
    data.registration_start_date = moment(data.registration_start_date);
    data.registration_end_date = moment(data.registration_end_date);
  }
  return data;
};

export const updateShowById = async ({
  show_name,
  estimated_entries,
  show_dates,
  contact_first_name,
  contact_last_name,
  contact_email,
  contact_phone_number,
  facility_name,
  facility_city,
  facility_state,
  facility_map,
  show_description,
  facility_postal_code,
  facility_street_address,
  premium_book_link,
  allow_substitutions,
  allow_late_registrations,
  registration_dates,
  status,
  species_breeds,
  id,
}) => {
  const payload = mapObjIndexed(
    value => {
      if (value === true) return 1;
      if (value === false) return 0;
      return value;
    },
    reject(isNil, {
      show_name,
      estimated_entries,
      show_start_date: show_dates.startDate.format(apiDateFormat),
      show_end_date: show_dates.endDate.format(apiDateFormat),
      registration_start_date: registration_dates.startDate.format(
        apiDateFormat
      ),
      registration_end_date: registration_dates.endDate.format(apiDateFormat),
      contact_first_name,
      contact_last_name,
      contact_email,
      contact_phone_number,
      facility_name,
      facility_city,
      facility_state,
      // Only put the facility_map key if it is a new file
      ...(facility_map ? { facility_map } : {}),
      show_description,
      facility_postal_code,
      facility_street_address,
      premium_book_link,
      allow_substitutions,
      allow_late_registrations,
      status,
      _method: 'PUT',
    })
  );

  const formData = toFormData(payload);
  species_breeds.forEach(id => {
    formData.append('species_breeds[]', id);
  });

  if (facility_map === null) {
    formData.append('facility_map', '');
  }

  return formatResponse(API.post(`/shows/${id}`, formData));
};

/**
 * @param {boolean} options.started - If true, starts the class; if false, stops the class
 */
export const setClassStatus = ({ showId, competitionGroupId, isInTheRing }) => {
  const path = `/shows/${showId}/competition_groups/${competitionGroupId}/start`;
  return formatResponse(isInTheRing ? API.post(path) : API.delete(path));
};

export const setShowStatus = ({ showId, status }) => {
  return formatResponse(API.put(`/shows/${showId}/status`, { status }));
};

// Registration

export const getRegistrationFileById = async ({ showId }) => {
  return formatResponse(API.get(`/shows/${showId}/registration`));
};

export const bulkRegister = ({ showId, registration_file }) => {
  const payload = toFormData({ registration: registration_file });
  return formatResponse(API.post(`/shows/${showId}/registration`, payload));
};
