import React from 'react';
import { useField } from 'formik';
import { Input } from '@chakra-ui/core';
import FieldWrapper from 'components/FieldWrapper';
import PropTypes from 'prop-types';

const TextInput = ({
  label,
  type,
  helperText,
  containerProps,
  disableErrors,
  ...props
}) => {
  const [field, meta] = useField(props);

  return (
    <FieldWrapper
      meta={meta}
      label={label}
      helperText={helperText}
      disableErrors={disableErrors}
      containerProps={containerProps}
    >
      <Input
        isInvalid={!!(meta.touched && meta.error) && !disableErrors}
        focusBorderColor="primary.500"
        errorBorderColor="red.400"
        type={type}
        {...field}
      />
    </FieldWrapper>
  );
};

TextInput.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  helperText: PropTypes.node,
  containerProps: PropTypes.object,
  disableErrors: PropTypes.bool,
};

export default TextInput;
