import API from './API';
import { formatResponse } from './utils';

export const register = async ({
  first_name,
  last_name,
  username,
  email,
  password,
  password_confirmation,
  city,
  state,
}) => {
  return formatResponse(
    API.post('/auth/register', {
      first_name,
      last_name,
      username,
      email,
      password,
      password_confirmation,
      city,
      state,
    })
  );
};

export const login = async ({ username, password }) => {
  return API.post('/auth/login', {
    username,
    password,
  });
};

export const forgotPassword = async ({ email }) => {
  return API.post('/auth/forgot-password', { email });
};

export const resetPassword = async ({
  email,
  token,
  password,
  password_confirmation,
}) => {
  return formatResponse(
    API.post('/auth/reset-password', {
      email,
      token,
      password,
      password_confirmation,
    })
  );
};

export const getMe = () => {
  return API.get('/me/profile');
};

export const verifyEmail = ({ token, userId }) => {
  return formatResponse(API.post('/auth/verify-email', { token, userId }));
};

export const resendEmail = async ({ email }) => {
  return API.post('/auth/resend-email', { email });
};
