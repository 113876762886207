import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Link as RLink } from 'react-router-dom';

import { Flex, Box } from '@chakra-ui/core';

import Text from 'components/Text';
import Button from 'components/Button';
import CardPage from 'components/CardPage';
import TextInput from 'components/TextInput';
import TermsAndConditionsPrivacyPolicy from 'components/TermsConditionsPrivacyPolicy';

import { forgotPassword } from 'services/API/auth';
import { useMutation } from 'react-query';

const LoginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email address')
    .required('No email provided'),
});

const ForgotPassword = () => {
  const [mutate, { data }] = useMutation(forgotPassword);

  return (
    <Formik
      initialValues={{
        email: '',
      }}
      validationSchema={LoginValidationSchema}
      onSubmit={async (values, { setSubmitting, setFieldError }) => {
        try {
          await mutate(values);
        } catch (error) {
          if (error.response.status === 400) {
            setFieldError(
              'email',
              'There is no account associated with this address.'
            );
          }
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {props => (
        <CardPage>
          <Flex
            direction="column"
            alignItems="center"
            flex={1}
            justifyContent="space-between"
            mx="auto"
            maxW="sm"
          >
            {data ? (
              <Flex direction="column" alignItems="center">
                <Text textAlign="center">
                  Thanks! An email will be sent shortly to reset your password.
                </Text>
                <Button
                  my="6"
                  as={RLink}
                  variant="ghost"
                  to="/login"
                  textDecoration="none"
                >
                  Return to login
                </Button>
              </Flex>
            ) : (
              <Form>
                <Flex direction="column">
                  <Text variant="paragraph" color="gray.400" textAlign="center">
                    Enter your email address and we will send you a link to
                    reset your password.
                  </Text>

                  <TextInput name="email" label="Email" type="email" />

                  <Flex justify="center" mt="12" mb="27px">
                    <Button
                      isLoading={props.isSubmitting}
                      isDisabled={!props.isValid}
                      type="submit"
                    >
                      Send
                    </Button>
                  </Flex>
                  <Flex justify="center" mb="12">
                    <Button
                      mb="6"
                      as={RLink}
                      variant="ghost"
                      to="/login"
                      textDecoration="none"
                    >
                      Return to login
                    </Button>
                  </Flex>
                </Flex>
              </Form>
            )}

            <TermsAndConditionsPrivacyPolicy mt="auto" pt="10" />
          </Flex>
        </CardPage>
      )}
    </Formik>
  );
};

ForgotPassword.propTypes = {
  isSubmitting: PropTypes.bool,
  isValid: PropTypes.bool,
};

export default ForgotPassword;
