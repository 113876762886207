import React from 'react';
import PropTypes from 'prop-types';
import { Link as RLink } from 'react-router-dom';
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogHeader,
  AlertDialogFooter,
  AlertDialogBody,
} from '@chakra-ui/core';

import Button from 'components/Button';
import Text from 'components/Text';

const ConfirmationModal = ({
  isOpen,
  onClose,
  body,
  title,
  buttonText,
  buttonColor,
  redirect,
}) => {
  return (
    <AlertDialog isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <AlertDialogOverlay />
      <AlertDialogContent px={4} py={5} maxW="660px" h="314px">
        <AlertDialogHeader>
          <Text variant="h1">{title}</Text>
        </AlertDialogHeader>
        <AlertDialogBody>{body}</AlertDialogBody>

        <AlertDialogFooter>
          <Button
            onClick={onClose}
            as={RLink}
            to={redirect}
            minW="189px"
            variantColor={buttonColor}
          >
            {buttonText}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

ConfirmationModal.propTypes = {
  body: PropTypes.string,
  title: PropTypes.string,
  buttonText: PropTypes.string,
  buttonColor: PropTypes.string,
  redirect: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ConfirmationModal;
