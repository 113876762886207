import React from 'react';
import { ThemeProvider, CSSReset } from '@chakra-ui/core';
import 'normalize.css';
import {
  Redirect,
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import { ReactQueryConfigProvider } from 'react-query';
import 'react-dates/lib/css/_datepicker.css';
import 'react_dates_override.css';

import GlobalStyles from './GlobalStyles';

/* This theme file can be used to create consistent spacing, colors, and fonts across the app.
   See the `styled-system` theme documentation for more information on the format.
 */
import customTheme from './theme';

import Login from './views/Login';
import CreateAccount from './views/CreateAccount';
import ForgotPassword from './views/ForgotPassword';
import ResetPassword from './views/ResetPassword';
import VerifyEmail from './views/VerifyEmail';
import ShowList from './views/ShowList';
import Placement from './views/Placement';
import RequestAShow from './views/RequestAShow';
import ShowInfo from './views/ShowInfo';
import Registration from './views/Registration';
import EditShow from './views/EditShow';
import NotFound from './views/NotFound';

import { AuthProvider } from './components/AuthContext';
import PrivateRoute from './components/PrivateRoute';

const queryConfig = {
  retry: false,
};

function App() {
  return (
    <Router>
      <ReactQueryConfigProvider config={queryConfig}>
        <GlobalStyles />
        <AuthProvider>
          <ThemeProvider theme={customTheme}>
            <CSSReset />
            <Switch>
              <Route path="/" exact render={() => <Redirect to={'/login'} />} />
              <Route path="/login" component={Login} />
              <Route path="/create-account" component={CreateAccount} />
              <Route path="/forgot-password" component={ForgotPassword} />
              <Route path="/reset-password" component={ResetPassword} />
              <Route path="/verify-email" component={VerifyEmail} />
              <PrivateRoute path="/request-show" component={RequestAShow} />
              <PrivateRoute exact path="/shows" component={ShowList} />
              <PrivateRoute exact path="/shows/:showId" component={ShowInfo} />
              <PrivateRoute
                path="/shows/:showId/placement"
                component={Placement}
              />
              <PrivateRoute path="/shows/:showId/edit" component={EditShow} />
              <PrivateRoute
                path="/shows/:showId/registration"
                component={Registration}
              />

              <Route component={NotFound} />
            </Switch>
          </ThemeProvider>
        </AuthProvider>
      </ReactQueryConfigProvider>
    </Router>
  );
}

export default App;
