import { conformToMask } from 'react-text-mask';

const phoneMask = [
  '(',
  /\d/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const formatPhoneNumber = string => {
  return conformToMask(string, phoneMask).conformedValue;
};

export default phoneMask;
