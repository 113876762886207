import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Flex } from '@chakra-ui/core';
import { Link as RLink, withRouter, Redirect } from 'react-router-dom';

import Text from 'components/Text';
import Button from 'components/Button';
import Link from 'components/Link';
import { useAuth } from 'components/AuthContext';
import TextInput from 'components/TextInput';
import TermsConditionsPrivacyPolicy from 'components/TermsConditionsPrivacyPolicy';
import CardPage from 'components/CardPage';

import { login } from 'services/API/auth';
import { refetchQuery } from 'react-query';

const LoginValidationSchema = Yup.object().shape({
  username: Yup.string().required('Username is required'),
  password: Yup.string().required('Password is required'),
});

export const LoginForm = () => {
  const auth = useAuth();

  return (
    <Formik
      initialValues={{
        username: '',
        password: '',
      }}
      validationSchema={LoginValidationSchema}
      onSubmit={async (values, { setSubmitting, setErrors, setFieldError }) => {
        try {
          const { data } = await login(values);

          if (!data.data.email_verified) {
            setFieldError('username', 'Your email is not verified');
          } else {
            // Set the token
            auth.login(data.data.token_info.token);
            // Go refetch the 'me' data from the server using the new token
            refetchQuery('me');
          }
        } catch (error) {
          // We can infer that the username/password combo does not match any records
          setErrors({ username: true, password: 'Invalid login' });
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {props => (
        <CardPage>
          <Flex
            direction="column"
            justifyContent="space-between"
            height="100%"
            alignItems="center"
          >
            <Flex width="350px" justifyContent="center" direction="column">
              <Form onSubmit={props.handleSubmit}>
                <TextInput name="username" label="Username" type="text" />
                <TextInput name="password" label="Password" type="password" />
                <Flex justifyContent="flex-end" mb="12" mt="4">
                  <Link to="/forgot-password">
                    <Text color="primary.500">Forgot your password?</Text>
                  </Link>
                </Flex>
                <Flex justifyContent="center" mb="8">
                  <Button
                    isDisabled={!props.isValid}
                    isLoading={props.isSubmitting}
                    type="submit"
                  >
                    Login
                  </Button>
                </Flex>
                <Flex justifyContent="center" mb="16">
                  <Button
                    variant="ghost"
                    variantColor="primary"
                    as={RLink}
                    to="/create-account"
                    textDecoration="none"
                  >
                    Create an account
                  </Button>
                </Flex>
              </Form>
            </Flex>

            <TermsConditionsPrivacyPolicy mt="auto" />
          </Flex>
        </CardPage>
      )}
    </Formik>
  );
};
LoginForm.propTypes = {
  isValid: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
};

const LoginPage = () => {
  const { isLoggedIn } = useAuth();

  return isLoggedIn ? <Redirect to={'/shows'} /> : <LoginForm />;
};
LoginPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default withRouter(LoginPage);
