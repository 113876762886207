import React from 'react';
import PropTypes from 'prop-types';
import { mapObjIndexed, values, map, propOr, prop, pipe, groupBy } from 'ramda';
import {
  Flex,
  Box,
  Stack,
  Divider,
  Checkbox,
  Tag,
  Link as ChakraLink,
  AlertDialog,
  AlertDialogBody,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogCloseButton,
  useDisclosure,
} from '@chakra-ui/core';
import { useQuery, useMutation } from 'react-query';
import { useParams, Link as RLink } from 'react-router-dom';
import moment from 'moment';

import { fetchShowById, setShowStatus } from 'services/API/shows';
import { formatPhoneNumber } from 'Utilities/textMasks/phoneMask';
import titleCase from 'Utilities/titleCase';
import useToast from 'Hooks/useToast';
import { useAuth } from 'components/AuthContext';

import Text from 'components/Text';
import Link from 'components/Link';
import Page from 'components/Page';
import {
  Errored,
  ErroredHeader,
  ErroredBody,
  ErroredRefreshButton,
} from 'components/Errored';
import PageHeader from 'components/PageHeader';
import {
  SectionCard,
  SectionContent,
  SectionHeader,
  SectionRightElement,
} from 'components/SectionCard';
import Button from 'components/Button';

import ShowStaffPanel from './ShowStaffPanel';

const formatDate = date => {
  if (!date) return null;
  return date.format('MM/DD/YYYY');
};

const statusToColor = status => {
  const mapping = {
    requested: 'yellow',
    approved: 'green',
    published: 'primary',
    declined: 'red',
  };
  return mapping[status] || 'gray';
};

const SubSection = ({ heading, children, ...props }) => {
  return (
    <Box aria-label={heading} {...props}>
      <Text variant="formLabel" color="gray.400">
        {heading}
      </Text>
      <Divider />
      <Stack spacing="5" my="6">
        {children}
      </Stack>
    </Box>
  );
};
SubSection.propTypes = {
  heading: PropTypes.node,
  children: PropTypes.node,
};

const MaybeText = ({ children, ...props }) => {
  if (children) {
    return (
      <Text variant="paragraph" {...props}>
        {children}
      </Text>
    );
  }
  return <Text variant="paragraph">--</Text>;
};
MaybeText.propTypes = {
  children: PropTypes.string,
};

const ShowInfo = () => {
  const toast = useToast();
  const { showId } = useParams();
  const auth = useAuth();
  const isSuperAdmin = auth.me && auth.me.is_super_admin;

  const showCacheKey = ['shows', { id: showId }];
  const { isLoading, error, data } = useQuery(showCacheKey, fetchShowById);

  const show = data || {};

  const species = pipe(
    propOr([], 'species_breeds'),
    groupBy(prop('species')),
    map(map(prop('breed')))
  )(show);

  const checkboxProps = {
    as: 'div',
    cursor: 'not-allowed',
    variantColor: 'primary',
    opacity: 0.5,
    mr: '3',
  };

  const [updateStatus, { isLoading: isStatusUpdating }] = useMutation(
    setShowStatus,
    {
      refetchQueries: [['shows', { id: showId }]],
    }
  );
  const handleStatusChange = async ({ status }) => {
    try {
      await updateStatus({ showId: showId, status });
      cancelModalState.onClose();
      toast({
        title: 'Show Canceled',
        description:
          'If you would like to create a new show, please submit a new request.',
        status: 'success',
        isClosable: true,
      });
    } catch (e) {
      // For developer visibility
      console.error(e);
      toast({
        title: `${e.status}: Failed to cancel show`,
        description: e.error.message,
        status: 'error',
        isClosable: true,
      });
    }
  };
  const cancelModalState = useDisclosure();

  const cancelModal = (
    <AlertDialog {...cancelModalState}>
      <AlertDialogOverlay />
      <AlertDialogContent p="8" maxW="2xl">
        <AlertDialogHeader>
          <Text variant="h1">Cancel Show Request</Text>
        </AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          <Text variant="paragraph">
            Are you sure? Canceling a show request cannot be undone.
          </Text>
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button
            isLoading={isStatusUpdating}
            onClick={() => handleStatusChange({ status: 'declined' })}
            variantColor="red"
          >
            Yes, cancel
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );

  return (
    <Page isLoading={isLoading}>
      {cancelModal}
      <PageHeader>Show Info</PageHeader>
      <Stack isInline spacing="4">
        <SectionCard flex="2" position="relative">
          {error ? (
            <Errored>
              <ErroredHeader large>Show Details Error</ErroredHeader>
              <ErroredBody maxW="sm" textAlign="center">
                There was an error in displaying this show’s details. Refresh to
                try again.
              </ErroredBody>
              <ErroredRefreshButton />
            </Errored>
          ) : (
            <>
              <SectionHeader pr="0">
                Show Details
                <Tag
                  as="span"
                  ml="3"
                  variant="outline"
                  variantColor={statusToColor(show.status)}
                >
                  {titleCase(show.status || '')}
                </Tag>
                <SectionRightElement>
                  <Flex align="center">
                    {show.updated_at && (
                      <Text variant="paragraph" color="gray.400" mr="4">
                        Last updated:{' '}
                        {moment(show.updated_at).format('MM/DD/YY h:mm a')}
                      </Text>
                    )}

                    {// Only show the edit button in these 3 statuses
                    (['published', 'unpublished', 'approved'].includes(
                      show.status
                    ) ||
                      (isSuperAdmin && show.status !== 'declined')) && (
                      <Button
                        as={RLink}
                        to={`/shows/${showId}/edit`}
                        variant="link"
                        mr="4"
                      >
                        edit
                      </Button>
                    )}

                    {show.status === 'requested' && !isSuperAdmin && (
                      <Button
                        type="button"
                        variant="link"
                        mr="4"
                        onClick={cancelModalState.onOpen}
                      >
                        cancel show
                      </Button>
                    )}
                  </Flex>
                </SectionRightElement>
              </SectionHeader>
              <SectionContent>
                <SubSection heading="Primary Contact">
                  <Flex>
                    <Box width="180px" pr="20px">
                      <Text variant="formLabel">First Name</Text>
                      <MaybeText>{show.contact_first_name}</MaybeText>
                    </Box>

                    <Box>
                      <Text variant="formLabel">Last Name</Text>
                      <MaybeText>{show.contact_last_name}</MaybeText>
                    </Box>
                  </Flex>

                  <Flex>
                    <Box width="180px" pr="20px">
                      <Text variant="formLabel">Phone</Text>
                      <MaybeText>
                        {formatPhoneNumber(show.contact_phone_number)}
                      </MaybeText>
                    </Box>

                    <Box>
                      <Text variant="formLabel">Email</Text>
                      <MaybeText>{show.contact_email}</MaybeText>
                    </Box>
                  </Flex>
                </SubSection>

                <SubSection heading={`Dates & Registration`}>
                  <Flex>
                    <Box width="180px" pr="20px">
                      <Text variant="formLabel">Show Open</Text>
                      <MaybeText>{formatDate(show.show_start_date)}</MaybeText>
                    </Box>

                    <Box>
                      <Text variant="formLabel">Show Close</Text>
                      <MaybeText>{formatDate(show.show_end_date)}</MaybeText>
                    </Box>
                  </Flex>

                  <Flex>
                    <Box width="180px" pr="20px">
                      <Text variant="formLabel">Registration Open</Text>
                      <MaybeText>
                        {formatDate(show.registration_start_date)}
                      </MaybeText>
                    </Box>

                    <Box>
                      <Text variant="formLabel">Registration Close</Text>
                      <MaybeText>
                        {formatDate(show.registration_end_date)}
                      </MaybeText>
                    </Box>
                  </Flex>

                  <Flex as="label">
                    <Checkbox
                      {...checkboxProps}
                      isChecked={show.allow_late_registrations}
                    />
                    <Text id="late-registration" variant="paragraph">
                      Accepting late registration
                    </Text>
                  </Flex>

                  <Flex as="label">
                    <Checkbox
                      {...checkboxProps}
                      isChecked={show.allow_substitutions}
                    />
                    <Text variant="paragraph">Accepting substitutions</Text>
                  </Flex>
                </SubSection>

                <SubSection heading="Show Details">
                  <Box>
                    <Text variant="formLabel">Show Name</Text>
                    <MaybeText>{show.show_name}</MaybeText>
                  </Box>

                  <Box>
                    <Text variant="formLabel">Estimated Number of Entries</Text>
                    <MaybeText>
                      {show.estimated_entries &&
                        Number(show.estimated_entries).toLocaleString()}
                    </MaybeText>
                  </Box>

                  <Box>
                    <Text variant="formLabel">Show Description</Text>
                    <MaybeText>{show.show_description}</MaybeText>
                  </Box>

                  <Box>
                    <Text variant="formLabel">Premium Book Link</Text>
                    <Link
                      as="a"
                      color="primary.500"
                      isExternal
                      href={show.premium_book_link}
                    >
                      View book
                    </Link>
                  </Box>
                </SubSection>

                <SubSection heading="Location">
                  <Box>
                    <Text variant="formLabel">Facility Name</Text>
                    <MaybeText>{show.facility_name}</MaybeText>
                  </Box>

                  <Box>
                    <Text variant="formLabel">Street Address</Text>
                    <MaybeText>{show.facility_street_address}</MaybeText>
                  </Box>

                  <Stack isInline spacing="10">
                    <Box>
                      <Text variant="formLabel">City</Text>
                      <MaybeText>{show.facility_city}</MaybeText>
                    </Box>

                    <Box>
                      <Text variant="formLabel">State</Text>
                      <MaybeText>{show.facility_state}</MaybeText>
                    </Box>

                    <Box>
                      <Text variant="formLabel">Postal Code</Text>
                      <MaybeText>{show.facility_postal_code}</MaybeText>
                    </Box>
                  </Stack>

                  <Box>
                    <Text variant="formLabel">Facility Map</Text>
                    <MaybeText>
                      {show.facility_map ? (
                        <Link
                          as={ChakraLink}
                          isExternal
                          href={show.facility_map}
                        >
                          View map
                        </Link>
                      ) : null}
                    </MaybeText>
                  </Box>
                </SubSection>

                <SubSection heading="Animals">
                  {values(
                    mapObjIndexed(
                      (breeds, species) => (
                        <Box key={species} aria-label={species}>
                          <Text variant="formLabel">{species} Breeds</Text>
                          {breeds.map(breed => {
                            return <MaybeText key={breed}>{breed}</MaybeText>;
                          })}
                        </Box>
                      ),
                      species
                    )
                  )}
                </SubSection>
              </SectionContent>
            </>
          )}
        </SectionCard>

        <ShowStaffPanel showStatus={show.status} />
      </Stack>
    </Page>
  );
};

export default ShowInfo;
