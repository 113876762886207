import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Divider, Icon, Tooltip } from '@chakra-ui/core';
import Text from 'components/Text';

const PageHeader = ({ children, tooltip }) => {
  return (
    <>
      <Flex align="flex-end">
        <Text as="h1" variant="h1">
          {children}
        </Text>
        {tooltip && (
          <Tooltip hasArrow label={tooltip} placement="left">
            <Icon ml="auto" name="question-outline" />
          </Tooltip>
        )}
      </Flex>
      <Divider mt="3" mb="4" />
    </>
  );
};
PageHeader.propTypes = {
  children: PropTypes.node,
  tooltip: PropTypes.node,
};

export default PageHeader;
