/*
Component used to store the current authentication state of the user.
*/
import React, { useState } from 'react';
import { useQuery, clearQueryCache } from 'react-query';

import { handleTokenChange } from 'services/API';
import { getMe } from 'services/API/me';
import { Redirect } from 'react-router';

const AuthContext = React.createContext();

export const AuthProvider = props => {
  const [token, setToken] = useState(localStorage.getItem('auth_token'));
  const [isInterceptorSet, setIsInterceptorSet] = useState(false);

  React.useEffect(() => {
    // Update axios with the latest token each time it changes
    handleTokenChange(token);
    setIsInterceptorSet(true);
  }, [token]);

  function onLogin(newToken) {
    localStorage.setItem('auth_token', newToken);
    setToken(newToken);
  }

  function onLogout() {
    localStorage.removeItem('auth_token');
    clearQueryCache();
    setToken(null);
  }

  const { isLoading, error, data } = useQuery(
    token && isInterceptorSet && 'me',
    () => getMe({ token })
  );
  const me = data && data;

  if (error && token) {
    onLogout();
    return <Redirect to="/login" />;
  }

  const providerValue = {
    token: isInterceptorSet ? token : null,
    isLoggedIn: token && isInterceptorSet,
    login: onLogin,
    logout: onLogout,
    isLoading: isLoading || (!data && !isLoading && !error && token),
    me,
  };

  return <AuthContext.Provider value={providerValue} {...props} />;
};

/**
 * @returns Object: {
 *   token: string,
 *   isLoggedIn: bool,
 *   login: (token) => void,
 *   logout: () => void,
 *   me: remote user object
 *   isLoading: bool // true when user data is loading
 * }
 */
export const useAuth = () => React.useContext(AuthContext);
