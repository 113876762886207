import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Flex,
  CloseButton,
  Icon,
  useToast as useChakraToast,
} from '@chakra-ui/core';

import {
  MdCheckCircle as CheckIcon,
  MdAddCircle as CloseIcon,
} from 'react-icons/md';

import Text from 'components/Text';

const Toast = ({ onClose, status, title, description }) => {
  const color = status === 'error' ? 'red.400' : 'green.500';
  const icon = status === 'error' ? CloseIcon : CheckIcon;

  return (
    <Box bg={color} p="6" position="relative" pr="12">
      <CloseButton
        onClick={onClose}
        position="absolute"
        top="4"
        right="4"
        color="white"
      />
      <Flex>
        <Icon
          as={icon}
          color="white"
          mr="4"
          fontSize="24px"
          transform={status === 'error' ? 'rotate(45deg)' : 'none'}
        />
        <Box textAlign="left">
          <Text variant="h4" color="white">
            {title}
          </Text>
          <Text variant="paragraph" color="white">
            {description}
          </Text>
        </Box>
      </Flex>
    </Box>
  );
};
Toast.propTypes = {
  onClose: PropTypes.func,
  status: PropTypes.oneOf(['success', 'error']),
  title: PropTypes.string,
  description: PropTypes.string,
};

const useToast = defaultOptions => {
  const toast = useChakraToast();

  return options => {
    const renderToast = ({ onClose }) => (
      <Toast onClose={onClose} {...defaultOptions} {...options} />
    );
    renderToast.propTypes = {
      onClose: PropTypes.func,
    };
    toast({
      isClosable: true,
      render: renderToast,
      status: 'success',
      ...defaultOptions,
      ...options,
    });
  };
};

export default useToast;
