import React from 'react';
import { useQuery } from 'react-query';
import {
  descend,
  sortWith,
  map,
  toPairs,
  pipe,
  groupBy,
  prop,
  defaultTo,
} from 'ramda';
import { Box } from '@chakra-ui/core';

import { getStates } from 'services/API/metadata';
import SelectInput from 'components/SelectInput';

const StateSelectInput = ({ ...props }) => {
  const { data } = useQuery('states', getStates);
  const options = pipe(
    defaultTo([]),
    sortWith([descend(prop('label'))])
  )(data);

  const formatLabel = (data, restFields) => {
    if (restFields.context === 'menu') {
      return <div>{data.full_name}</div>;
    } else {
      return <div>{data.abbreviation}</div>;
    }
  };

  return (
    <Box {...props}>
      <SelectInput
        getOptionLabel={prop('full_name')}
        getOptionValue={prop('abbreviation')}
        formatOptionLabel={formatLabel}
        options={options}
        placeholder=""
        noOptionsMessage={({ inputValue }) => {
          if (inputValue) {
            return 'No Options';
          }
          return 'Options failed to load. Please refresh the page and try again.';
        }}
        styles={{
          menu: provided => ({ ...provided, width: '220px', right: 0 }),
        }}
        {...props}
      />
    </Box>
  );
};

export default StateSelectInput;
