import React, { useState, useEffect } from 'react';
import { Flex, Link as ChakraLink } from '@chakra-ui/core';
import { Link as RLink } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useMutation } from 'react-query';
import queryString from 'query-string';

import { verifyEmail } from 'services/API/auth';

import CardPage from 'components/CardPage';
import Text from 'components/Text';
import Button from 'components/Button';

let linkToMobileApp = null;
const device = window.navigator.userAgent.toLowerCase();

if (device.indexOf('iphone') != -1 || device.indexOf('ipad') != -1)
  linkToMobileApp = 'ringside://verification';
if (device.indexOf('android') != -1)
  linkToMobileApp = `intent://login/#Intent;scheme=login;package=${process.env.REACT_APP_ANDROID_PACKAGE_NAME};S.browser_fallback_url=${window.location.origin}/login;end`;

const VerifyEmail = () => {
  const [mutate, { isLoading }] = useMutation(verifyEmail);
  const location = useLocation();
  const [error, setError] = useState(null);

  useEffect(() => {
    const params = queryString.parse(location.search);

    const genericErrorMessage =
      'Oops! Something went wrong when verifying your email address. Please return to your inbox and try again.';

    if (!params.token || !params.userId) {
      setError(genericErrorMessage);
      return;
    }

    mutate({ token: params.token, userId: params.userId }).catch(() => {
      setError(genericErrorMessage);
    });
  }, []);

  return (
    <CardPage minHeight="800px" isLoading={isLoading}>
      <Flex direction="column" align="center" mb="16">
        <Text variant="paragraph" color="gray.400" textAlign="center">
          {error || 'Thank you! Your email address has been verified.'}
        </Text>

        {!error && linkToMobileApp ? (
          <Button as={ChakraLink} href={linkToMobileApp} isExternal mt="40">
            Return to login
          </Button>
        ) : (
          <Button as={RLink} to="/login" mt="40">
            Return to login
          </Button>
        )}
      </Flex>
    </CardPage>
  );
};

export default VerifyEmail;
