import React from 'react';
import { useQuery, useMutation } from 'react-query';
import {
  keys,
  filter,
  clone,
  identity,
  any,
  fromPairs,
  range,
  cond,
  pipe,
  values as objValues,
  equals,
  always,
} from 'ramda';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { useParams } from 'react-router-dom';
import { Link as RLink } from 'react-router-dom';
import {
  Flex,
  Box,
  Accordion,
  Spinner,
  AlertDialog,
  AlertDialogBody,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogCloseButton,
  useDisclosure,
  Tag,
  TagLabel,
  TagCloseButton,
  Icon,
  Link as ChakraLink,
  PseudoBox,
} from '@chakra-ui/core';

import { MdUndo as UndoIcon, MdChevronLeft } from 'react-icons/md';

import {
  fetchShowById,
  updateShowById,
  setShowStatus,
} from 'services/API/shows';
import { phoneNumberValidator } from 'Utilities/validators';

import Text from 'components/Text';
import Link from 'components/Link';
import Divider from 'components/Divider';
import TextInput from 'components/TextInput';
import Button from 'components/Button';
import DateRangeInput, { dateRangeSchema } from 'components/DateRangeInput';
import FormAccordionItem from 'components/FormAccordionItem';
import TextAreaInput from 'components/TextAreaInput';
import AnimalBreedCheckboxes from 'components/AnimalBreedsCheckboxes';
import FileUploadInput from 'components/FileUploadInput';
import CheckboxInput from 'components/CheckboxInput';
import {
  Errored,
  ErroredBody,
  ErroredHeader,
  ErroredRefreshButton,
} from 'components/Errored';
import StateSelectInput from 'components/StateSelectInput';
import { formatPhoneNumber } from 'Utilities/textMasks/phoneMask';
import useToast from 'Hooks/useToast';
import { useHistory } from 'react-router';

const postalCodeRegex = /^\d{5}(-?\d{4})?$/;

const EditShowValidationSchema = Yup.object().shape({
  contact_first_name: Yup.string()
    .max(50, 'Must not exceed 15 characters.')
    .required('No name provided.'),
  contact_last_name: Yup.string()
    .max(50, 'Must not exceed 15 characters.')
    .required('No name provided.'),
  contact_phone_number: phoneNumberValidator.required(
    'No phone number provided.'
  ),
  contact_email: Yup.string()
    .email('Invalid email address.')
    .required('No email provided.'),
  show_dates: dateRangeSchema,
  registration_dates: dateRangeSchema,
  show_name: Yup.string()
    .max(50, 'Must not exceed 50 characters')
    .required('No name provided.'),
  estimated_entries: Yup.number().required(
    'Estimated number of entries required.'
  ),
  show_description: Yup.string().max(500, 'Must not exceed 500 characters.'),
  premium_book_link: Yup.string().url('Link must be a valid URL'),
  facility_name: Yup.string()
    .max(50, 'Must not exceed 50 characters.')
    .required('No name provided.'),
  facility_street_address: Yup.string()
    .max(50, 'Must not exceed 50 characters.')
    .required('No address provided.'),
  facility_city: Yup.string()
    .max(25, 'Must not exceed 25 characters.')
    .required('No city provided.'),
  facility_state: Yup.string()
    .min(2, 'Not enough characters')
    .required('No state provided.'),
  facility_postal_code: Yup.string()
    .matches(postalCodeRegex, 'Not a valid postal code.')
    .required('No postal code provided.'),
  species_breeds: Yup.object().test(
    'breeds-required',
    'At least one breed must be selected',
    species_breeds => {
      return pipe(
        objValues,
        any(identity)
      )(species_breeds);
    }
  ),
});

const EditShow = () => {
  const { showId } = useParams();
  const toast = useToast();
  const history = useHistory();

  const [mutate] = useMutation(updateShowById);
  const [updateStatus, updateStatusMutation] = useMutation(setShowStatus, {
    refetchQueries: [['shows', { id: showId }]],
  });
  const { data, isLoading, error } = useQuery(
    ['shows', { id: showId }],
    fetchShowById
  );

  const loadingView = (
    <Flex justify="center" align="center" height="lg">
      <Spinner color="primary.500" />
    </Flex>
  );

  const denyModalState = useDisclosure();
  const pauseModalState = useDisclosure();
  const handleStatusChange = async ({ status }) => {
    try {
      await updateStatus({ showId: showId, status });
      history.push(`/shows/${showId}`);
    } catch (e) {
      console.error(e);
      toast({
        title: `${e.status}: Failed to update show`,
        description: e.error.message,
        status: 'error',
        isClosable: true,
      });
      throw e;
    }
  };

  const displayApprovedToast = () => {
    toast({
      title: 'You have Approved this Request',
      description:
        'The user will be notified that their request for a show has been accepted. Their show will now be accessible for public view. ',
      status: 'success',
      isClosable: true,
    });
  };

  const denyModal = (
    <AlertDialog {...denyModalState}>
      <AlertDialogOverlay />
      <AlertDialogContent p="8" maxW="2xl">
        <AlertDialogHeader>
          <Text variant="h1">Deny Show Request</Text>
        </AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          <Text variant="paragraph">
            Are you sure? The user will be notified that their request for a
            show has been denied by Ringside Admin. They will have to re-submit
            for further consideration.
          </Text>
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button
            onClick={() => handleStatusChange({ status: 'declined' })}
            variantColor="red"
            isLoading={updateStatusMutation.isLoading}
          >
            deny request
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
  const pauseModal = (
    <AlertDialog {...pauseModalState}>
      <AlertDialogOverlay />
      <AlertDialogContent p="8" maxW="2xl">
        <AlertDialogHeader>
          <Text variant="h1">Take Show Offline</Text>
        </AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          <Text variant="paragraph">
            Are you sure? This will remove the show from the mobile app. You can
            publish the show to make it visible again.
          </Text>
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button
            onClick={() => handleStatusChange({ status: 'unpublished' })}
            variantColor="primary"
            isLoading={updateStatusMutation.isLoading}
          >
            yes, take offline
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );

  const errorView = (
    <Flex>
      <Errored>
        <ErroredHeader large>Show Loading Error</ErroredHeader>
        <ErroredBody>
          There was an error in displaying this show’s details. Refresh to try
          again.
        </ErroredBody>
        <ErroredRefreshButton />
        <Button as={RLink} to={`/shows/${showId}`} variant="ghost" mt="4">
          back to show
        </Button>
      </Errored>
    </Flex>
  );

  return (
    <>
      <Box width="100vw" bg="white" height="56px" />
      {denyModal}
      {pauseModal}
      <Flex
        minHeight="100vh"
        width="100vw"
        bg="gray.50"
        direction="column"
        alignItems="center"
      >
        {isLoading ? (
          loadingView
        ) : error ? (
          errorView
        ) : data ? (
          <Flex direction="column" mt="20">
            <Flex mb="6">
              <Icon as={MdChevronLeft} size="6" color="primary.400" />
              <Button as={RLink} to={`/shows/${showId}`} variant="link">
                back to show
              </Button>
            </Flex>
            <Flex justify="space-between">
              <Flex>
                <Text variant="h1">
                  {data.status === 'requested'
                    ? 'Review Show Request'
                    : 'Edit Show'}
                </Text>
              </Flex>
              <Flex alignSelf="flex-end">
                {data.status === 'requested' && (
                  <>
                    <Button
                      variant="link"
                      fontSize="md"
                      size="sm"
                      variantColor="green"
                      px="5px"
                      mr="6"
                      onClick={() => {
                        handleStatusChange({ status: 'approved' }).then(() => {
                          displayApprovedToast();
                        });
                        // Errorrs are handled by `handleStatusChange` itself
                      }}
                    >
                      approve request
                    </Button>
                    <Button
                      variant="link"
                      fontSize="md"
                      size="sm"
                      variantColor="red"
                      px="5px"
                      onClick={denyModalState.onOpen}
                    >
                      Deny Request
                    </Button>
                  </>
                )}
                {data.status === 'published' && (
                  <Button variant="link" onClick={pauseModalState.onOpen}>
                    take show offline
                  </Button>
                )}
              </Flex>
            </Flex>

            <Divider />
            <Formik
              initialValues={{
                show_name: data.show_name,
                estimated_entries: data.estimated_entries,
                show_dates: {
                  startDate: data.show_start_date,
                  endDate: data.show_end_date,
                },
                contact_first_name: data.contact_first_name,
                contact_last_name: data.contact_last_name,
                contact_email: data.contact_email,
                contact_phone_number: data.contact_phone_number
                  ? formatPhoneNumber(data.contact_phone_number)
                  : '',
                facility_name: data.facility_name,
                facility_city: data.facility_city,
                facility_state: data.facility_state,
                show_description: data.show_description || '',
                facility_postal_code: data.facility_postal_code || '',
                facility_street_address: data.facility_street_address || '',
                premium_book_link: data.premium_book_link || '',
                allow_substitutions: data.allow_substitutions ? true : false,
                allow_late_registrations: data.allow_late_registrations
                  ? true
                  : false,
                registration_dates: {
                  startDate: data.registration_start_date,
                  endDate: data.registration_end_date,
                },
                status: data.status,
                species_breeds: fromPairs(
                  data.species_breeds.map(b => {
                    return [`${b.id}`, true];
                  })
                ),
                facility_map: data.facility_map || undefined,
              }}
              validationSchema={EditShowValidationSchema}
              onSubmit={async (rawValues, { setSubmitting, setErrors }) => {
                const values = clone(rawValues);
                try {
                  // strip out all non-digits from the phone number
                  values.contact_phone_number = values.contact_phone_number.replace(
                    /\D/g,
                    ''
                  );
                  if (typeof values.facility_state === 'object') {
                    values.facility_state = values.facility_state.abbreviation;
                  }
                  values.species_breeds = keys(
                    filter(identity, values.species_breeds)
                  );
                  if (typeof values.facility_map === 'string') {
                    delete values.facility_map;
                  }
                  await mutate({ ...values, id: showId });

                  // Show a toast if the show just transitioned into the approved state
                  if (
                    data.status !== 'approved' &&
                    values.status === 'approved'
                  ) {
                    displayApprovedToast();
                  }
                  history.push(`/shows/${showId}`);
                } catch (error) {
                  // For easier debugging
                  console.error(error);
                  if (error.error && error.error.validation_messages) {
                    setErrors(error.error.validation_messages);
                  } else {
                    toast({
                      title: `${error.status}: Failed to update show`,
                      description: error.error && error.error.message,
                      status: 'error',
                      isClosable: true,
                    });
                  }
                } finally {
                  setSubmitting(false);
                }
              }}
            >
              {({
                isValid,
                submitCount,
                isSubmitting,
                validateForm,
                values,
                setFieldValue,
                submitForm,
                errors,
              }) => (
                <Form>
                  <Accordion
                    allowMultiple
                    width="1100px"
                    defaultIndex={range(0, 5)}
                  >
                    <FormAccordionItem label="Primary Contact">
                      <Flex>
                        <TextInput
                          name="contact_first_name"
                          label="First Name"
                          type="text"
                        />
                        <TextInput
                          name="contact_last_name"
                          label="Last Name"
                          type="text"
                        />
                      </Flex>

                      <TextInput name="contact_phone_number" label="Phone" />

                      <TextInput
                        name="contact_email"
                        label="Email"
                        type="email"
                      />
                    </FormAccordionItem>

                    <FormAccordionItem label="Date & Registration">
                      <Flex direction="column">
                        <DateRangeInput name="show_dates" label="Show Dates" />
                        <DateRangeInput
                          name="registration_dates"
                          label="Registration Dates"
                        />
                      </Flex>
                      <Flex
                        height="100%"
                        direction="column"
                        justify="space-between"
                      >
                        <CheckboxInput
                          name="allow_late_registrations"
                          mt="20px"
                        >
                          Accepting late registration
                        </CheckboxInput>
                        <CheckboxInput
                          name="allow_substitutions"
                          size="lg"
                          mt="10px"
                        >
                          Accepting substitutions
                        </CheckboxInput>
                      </Flex>
                    </FormAccordionItem>

                    <FormAccordionItem label="Show Details">
                      {data.status === 'requested' && (
                        <TextInput
                          name="show_name"
                          label="Show Name"
                          helperText="This cannot be edited after show request approval"
                        />
                      )}
                      <TextInput
                        type="number"
                        name="estimated_entries"
                        label="Estimated Number of Entries"
                      />
                      <TextAreaInput
                        max={500}
                        height="196px"
                        name="show_description"
                        label="Description"
                        helperText="Optional"
                      />
                      <TextInput
                        name="premium_book_link"
                        label="Premium Book Link"
                        helperText="Optional"
                      />
                    </FormAccordionItem>

                    <FormAccordionItem label="Location">
                      <TextInput
                        name="facility_name"
                        label="Facility Name"
                        type="text"
                      />
                      <TextInput
                        name="facility_street_address"
                        label="Street Address"
                        type="text"
                      />
                      <Flex width="100%">
                        <TextInput
                          containerProps={{ flex: '2' }}
                          name="facility_city"
                          label="City"
                          type="text"
                        />
                        <StateSelectInput
                          w="100px"
                          name="facility_state"
                          label="State"
                        />
                        <TextInput
                          containerProps={{ flex: '1' }}
                          name="facility_postal_code"
                          label="Postal Code"
                        />
                      </Flex>
                      {typeof values.facility_map === 'string' ? (
                        <>
                          <Text mt="3" variant="formLabel">
                            Facility Map
                          </Text>
                          <Tag
                            as={ChakraLink}
                            href={values.facility_map}
                            isExternal
                            variantColor="primary"
                            bg="primary.500"
                            color="white"
                            target="_blank"
                            _hover={{
                              textDecoration: 'none',
                            }}
                          >
                            <TagLabel
                              as={PseudoBox}
                              _hover={{
                                textDecoration: 'none',
                              }}
                            >
                              Current facility map
                            </TagLabel>
                            <TagCloseButton
                              aria-label="Delete current facility map"
                              ml="2"
                              color="white"
                              opacity="0.75"
                              rounded="md"
                              onClick={e => {
                                e.preventDefault();
                                setFieldValue('facility_map', null);
                              }}
                            />
                          </Tag>
                        </>
                      ) : (
                        <>
                          <FileUploadInput
                            name="facility_map"
                            label="Facility Map"
                            containerProps={{ mb: '0' }}
                            helperText="Optional"
                          />
                          {data.facility_map && (
                            <Link
                              as="button"
                              onClick={() =>
                                setFieldValue('facility_map', data.facility_map)
                              }
                              d="flex"
                              mt="2"
                            >
                              <Box
                                bg="primary"
                                rounded="full"
                                w="24px"
                                h="24px"
                              >
                                <Icon as={UndoIcon} mr="3" />
                              </Box>
                              Use existing upload
                            </Link>
                          )}
                        </>
                      )}
                    </FormAccordionItem>

                    <FormAccordionItem label="Animals">
                      <AnimalBreedCheckboxes
                        fieldValues={values.species_breeds}
                      />
                      {errors.species_breeds && (
                        <Text variant="paragraph" color="red.400" mt="6">
                          {errors.species_breeds}
                        </Text>
                      )}
                    </FormAccordionItem>
                  </Accordion>
                  <Flex justifyContent="flex-end" mb="40px">
                    <Button
                      variant={data.status === 'requested' ? 'solid' : 'ghost'}
                      isDisabled={!isValid && submitCount >= 1}
                      isLoading={isSubmitting}
                      onClick={() => {
                        submitForm();
                      }}
                      type="button"
                      display={data.status === 'published' ? 'none' : 'flex'}
                      minWidth="64"
                    >
                      {cond([
                        [equals('requested'), always('save')],
                        [equals('approved'), always('save without publishing')],
                        [
                          equals('unpublished'),
                          always('save without publishing'),
                        ],
                      ])(data.status)}
                    </Button>
                    <Button
                      isDisabled={!isValid && submitCount >= 1}
                      isLoading={isSubmitting}
                      onClick={() => {
                        if (!isSubmitting) {
                          if (data.status === 'requested') {
                            // In requested state, make approved
                            setFieldValue('status', 'approved');
                          } else if (
                            data.status === 'approved' ||
                            data.status === 'unpublished'
                          ) {
                            // In approved or unpublished state, make published
                            setFieldValue('status', 'published');
                          }
                        }
                        submitForm();
                      }}
                      type="button"
                      display={data.status === 'requested' ? 'none' : 'flex'}
                    >
                      {cond([
                        [equals('requested'), always('save & approve show')],
                        [equals('approved'), always('save & publish show')],
                        [equals('published'), always('save and update')],
                        [equals('unpublished'), always('save and publish')],
                      ])(data.status)}
                    </Button>
                  </Flex>
                </Form>
              )}
            </Formik>
          </Flex>
        ) : null}
      </Flex>
    </>
  );
};
export default EditShow;
