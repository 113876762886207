import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import moment from 'moment';
import { Flex, Icon, useDisclosure } from '@chakra-ui/core';

import { MdFileUpload, MdFileDownload } from 'react-icons/md';

import { getRegistrationFileById } from 'services/API/shows';

import Button from 'components/Button';
import Page from 'components/Page';
import PageHeader from 'components/PageHeader';
import Text from 'components/Text';
import UploadModal from './components/UploadModal';
import {
  SectionCard,
  SectionHeader,
  SectionContent,
} from 'components/SectionCard';

const Registration = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { showId } = useParams();
  const { isLoading, data } = useQuery(
    ['registrationFile', { showId }],
    getRegistrationFileById
  );
  const existingFile = data && data.registration_import;

  return (
    <Page>
      <PageHeader>Registration</PageHeader>
      {isOpen && (
        <UploadModal isOpen={true} onOpen={onOpen} onClose={onClose} />
      )}

      <SectionCard isLoading={isLoading}>
        <SectionHeader>Registration Import</SectionHeader>
        <SectionContent height="220px" minHeight="220px" pt="8">
          {existingFile ? (
            <>
              <Text variant="formLabel">Existing Registration</Text>
              <Text>
                {moment(existingFile.date_imported).format('MM/DD/YY h:mm A')}
              </Text>
              <Flex mt={5}>
                <Button
                  leftIcon={() => (
                    <Icon
                      as={MdFileDownload}
                      color="gray.400"
                      fontSize="24px"
                      mr="3"
                    />
                  )}
                  as="a"
                  href={existingFile.file}
                  download
                  variant="link"
                  variantColor="primary"
                >
                  download existing
                </Button>
                <Button
                  leftIcon={() => (
                    <Icon
                      as={MdFileUpload}
                      color="gray.400"
                      fontSize="24px"
                      mr="3"
                    />
                  )}
                  ml="10"
                  onClick={onOpen}
                  variant="link"
                  textDecoration="none"
                >
                  upload new
                </Button>
              </Flex>
            </>
          ) : (
            <Flex
              direction="column"
              justify="center"
              align="center"
              height="100%"
            >
              <Icon as={MdFileUpload} color="gray.200" fontSize="100px" />
              <Flex justifyContent="center">
                <Button
                  onClick={onOpen}
                  variant="ghost"
                  variantColor="primary"
                  textDecoration="none"
                >
                  upload csv
                </Button>
              </Flex>
            </Flex>
          )}
        </SectionContent>
      </SectionCard>
    </Page>
  );
};

export default Registration;

/*
The second state the page can be in, not working for now so just put it here
        <>
          <SectionCard>
            <Text variant="subText">{registrationFile.updated_at}</Text>
          </SectionCard>
        </>

*/
