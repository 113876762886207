import FormData from 'form-data';

export const formatResponse = async request => {
  try {
    const { data } = await request;
    return data.data;
  } catch ({ response }) {
    throw {
      error: response.data.error,
      status: response.status,
    };
  }
};

export const toFormData = object =>
  Object.keys(object).reduce((formData, key) => {
    formData.append(key, object[key]);
    return formData;
  }, new FormData());
