import React from 'react';
import PropTypes from 'prop-types';
import { Link as RLink } from 'react-router-dom';
import { Box, Flex, PseudoBox, Tooltip } from '@chakra-ui/core';
import { useQuery } from 'react-query';

import Text from 'components/Text';
import CardPage from 'components/CardPage';
import Button from 'components/Button';
import {
  Errored,
  ErroredBody,
  ErroredHeader,
  ErroredRefreshButton,
} from 'components/Errored';

import { getShows } from 'services/API/shows';
import { useAuth } from 'components/AuthContext';

import formatDateRange from 'Utilities/formatDateRange';

const toTitleCase = str => {
  return str.slice(0, 1).toUpperCase() + str.slice(1);
};

const showStatusToColor = {
  requested: 'yellow.500',
  approved: 'green.500',
  published: 'primary.500',
  unpublished: 'gray.400',
  declined: 'red.400',
};

const ShowList = () => {
  const { logout } = useAuth();
  const { isLoading: userLoading, me } = useAuth();

  const { isLoading, error, data } = useQuery(['showList'], getShows);
  const shows = data ? data : [];
  const atLeastOneShow = shows.length >= 1;

  return (
    <CardPage isLoading={isLoading || userLoading}>
      {/* Wraps all contents within the card */}
      <Flex align="center" justify="center" direction="column">
        {me && <Text variant="formLabel">Welcome, {me.first_name}!</Text>}
        <Text variant="paragraph" color="gray.400" mb="6" mt="2">
          Select which show you would like to view.
        </Text>
        {/* Wraps the shows, scrolls, and is slightly larger to fit the scrollbar */}
        <Flex
          justify="center"
          maxH={atLeastOneShow ? '380px' : '100%'}
          width="100%"
          overflowY="auto"
          mb="6"
        >
          {/* Wraps the actual shows and fits to the parent with slightly less width to accomodate the scrollbar */}
          <Flex direction="column" height="100%" width="90%" align="center">
            {atLeastOneShow ? (
              <>
                {error && (
                  <Errored>
                    <ErroredHeader large>Failed to load shows</ErroredHeader>
                    <ErroredBody>
                      Check your internet and refresh to try again.
                    </ErroredBody>
                    <ErroredRefreshButton />
                  </Errored>
                )}
                {shows.map(show => {
                  return (
                    <PseudoBox
                      as={RLink}
                      alignSelf="stretch"
                      to={`/shows/${show.id}`}
                      key={show.id}
                      bg="white"
                      border="1px"
                      borderColor="gray.200"
                      px="6"
                      py="3"
                      _hover={{
                        bg: 'gray.50',
                      }}
                    >
                      <Tooltip hasArrow label={show.show_name}>
                        <Text variant="formLabel" isTruncated>
                          {show.show_name}
                        </Text>
                      </Tooltip>
                      <Flex align="baseline" justify="space-between">
                        <Text
                          variant="formLabel"
                          color={showStatusToColor[show.status] || 'gray.400'}
                          mr="2"
                        >
                          {toTitleCase(show.status)}
                        </Text>
                        <Text variant="small" color="gray.400">
                          {formatDateRange(
                            show.show_start_date,
                            show.show_end_date
                          )}
                        </Text>
                      </Flex>
                    </PseudoBox>
                  );
                })}
              </>
            ) : (
              <>
                <Button
                  as={RLink}
                  to="/request-show"
                  variantColor="primary"
                  mt="24"
                  mb="56"
                >
                  request new show
                </Button>
                <Button
                  onClick={logout}
                  variant="ghost"
                  variantColor="primary"
                  mb="10"
                >
                  logout
                </Button>
              </>
            )}
          </Flex>
        </Flex>
        {atLeastOneShow && (
          <Button
            as={RLink}
            to="/request-show"
            variant="ghost"
            variantColor="primary"
            mb="12"
          >
            request new show
          </Button>
        )}
      </Flex>
    </CardPage>
  );
};

ShowList.propTypes = {
  showName: PropTypes.shape({ name: PropTypes.string }),
};

export default ShowList;
