import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  useDisclosure,
  AlertDialogCloseButton,
  AlertDialogFooter,
  List,
  ListItem,
  Flex,
  IconButton,
  Spinner,
} from '@chakra-ui/core';
import { Formik, Form } from 'formik';
import { useQuery, useMutation, setQueryData } from 'react-query';
import * as yup from 'yup';

import {
  getShowAdminsById,
  deleteShowAdmin,
  addShowAdmin,
} from 'services/API/shows';
import useToast from 'Hooks/useToast';

// Components
import { useAuth } from 'components/AuthContext';
import UserSelectInput from 'components/UserSelectInput';
import {
  SectionCard,
  SectionContent,
  SectionHeader,
} from 'components/SectionCard';
import Button from 'components/Button';
import Text from 'components/Text';
import { MdAddCircleOutline as AddIcon } from 'react-icons/md';
import {
  Errored,
  ErroredHeader,
  ErroredBody,
  ErroredRefreshButton,
} from 'components/Errored';

const AddShowStaffModal = props => {
  const { showId } = useParams();
  const [mutate] = useMutation(addShowAdmin);
  const showAdminCacheKey = ['showAdmins', { showId }];
  const showAdminResponse = useQuery(showAdminCacheKey, getShowAdminsById);

  return (
    <Formik
      initialValues={{ user: null }}
      validationSchema={yup.object().shape({
        user: yup
          .object()
          .nullable()
          .required('User is required'),
      })}
      onSubmit={async (
        { user },
        { setSubmitting, setFieldError, resetForm }
      ) => {
        try {
          const res = await mutate({ showId, userId: user.value });
          resetForm();
          // We should be able to use the `updateQuery` option on `useMutation`
          // but it doesn't work so we have to do this instead
          setQueryData(['showAdmins', { showId }], res, {
            shouldRefetch: false,
          });
          props.onClose();
        } catch (e) {
          setFieldError(
            'user',
            (e.error && e.error.message) ||
              'Unexpected error occurred while adding this user'
          );
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ isValid }) => {
        return (
          <AlertDialog {...props}>
            <AlertDialogOverlay />
            <Form>
              <AlertDialogContent p="8" maxW="2xl">
                <AlertDialogHeader>
                  <Text variant="h1">Show Staff</Text>
                </AlertDialogHeader>
                <AlertDialogCloseButton />
                <AlertDialogBody>
                  <Form>
                    <UserSelectInput
                      excludeIds={showAdminResponse.data}
                      isLoading={showAdminResponse.isLoading}
                      name="user"
                      label="User"
                      helperText="Type to search users"
                    />
                  </Form>
                </AlertDialogBody>
                <AlertDialogFooter>
                  <Button
                    isDisabled={!isValid}
                    variantColor="green"
                    type="submit"
                  >
                    Add
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </Form>
          </AlertDialog>
        );
      }}
    </Formik>
  );
};
AddShowStaffModal.propTypes = {
  onClose: PropTypes.func,
};

const RemoveShowStaffModal = ({ isOpen, userId, onClose }) => {
  const { showId } = useParams();
  const toast = useToast();

  const [removeAdmin, { isLoading }] = useMutation(deleteShowAdmin);

  const handleSubmit = async () => {
    const newShowAdmins = await removeAdmin({
      showId: showId,
      userId: userId,
    });
    // We should be able to use the `updateQuery` option on `useMutation`
    // but it doesn't work so we have to do this instead
    setQueryData(['showAdmins', { showId: showId }], newShowAdmins, {
      shouldRefetch: false,
    });
    onClose();
    toast({
      title: 'Show Staff Removed',
      description: 'This show staff member has been removed from the event.',
      status: 'success',
      isClosable: true,
    });
  };

  return (
    <AlertDialog isOpen={isOpen} onClose={onClose}>
      <AlertDialogOverlay />
      <AlertDialogContent p="8" maxW="2xl">
        <AlertDialogHeader>
          <Text variant="h1">Remove Show Staff</Text>
        </AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          Are you sure you would like to remove this user from show staff?
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button
            variantColor="red"
            onClick={handleSubmit}
            isLoading={isLoading}
          >
            yes, remove
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
RemoveShowStaffModal.propTypes = {
  isOpen: PropTypes.bool,
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClose: PropTypes.func,
};

const ShowStaffPanel = ({ showStatus }) => {
  const { showId } = useParams();
  const { me } = useAuth();

  const showAdminCacheKey = ['showAdmins', { showId }];
  const showAdminResponse = useQuery(showAdminCacheKey, getShowAdminsById);

  const addStaffModal = useDisclosure();
  // Used in the delete show admin modal
  const [staffUpForRemoval, setStaffUpForRemoval] = useState(null);
  const closeRemoveModal = () => setStaffUpForRemoval(null);
  const openRemoveModal = setStaffUpForRemoval;

  const isSuperAdmin = me && me.is_super_admin;

  // Alphabetically by first name and current user as first in the list
  if (showAdminResponse.data) {
    showAdminResponse.data.sort((current, next) => {
      const currentIsMe = current.id === me.id;
      const nextIsMe = next.id === me.id;
      const currentName = current.name;
      const nextName = next.name;
      if (currentIsMe) {
        return -1;
      }
      if (currentName > nextName) {
        return 1;
      } else if (nextIsMe) {
        return 1;
      }
      if (currentName < nextName && !currentIsMe) {
        return -1;
      } else if (currentIsMe) {
        return -1;
      } else {
        return 0;
      }
    });
  }

  return (
    <>
      <RemoveShowStaffModal
        isOpen={!!staffUpForRemoval}
        userId={staffUpForRemoval}
        onClose={closeRemoveModal}
      />
      <AddShowStaffModal
        isOpen={addStaffModal.isOpen}
        onClose={addStaffModal.onClose}
      />
      <SectionCard maxW="xs" height="100%" flex="1">
        <SectionHeader>Show Staff</SectionHeader>
        <SectionContent px="6">
          <List spacing="2" mb="6">
            {showAdminResponse.isLoading && (
              <Flex justify="center">
                <Spinner color="primary.500" my="8" />
              </Flex>
            )}
            {showAdminResponse.error && (
              <Errored minHeight="0" my="2" align="flex-start">
                <ErroredHeader>Show Staff Error</ErroredHeader>
                <ErroredBody>
                  There was an error in displaying this show’s staff.
                </ErroredBody>
                <ErroredRefreshButton
                  mt="3"
                  refetchQueries={['showAdmins', { showId }]}
                />
              </Errored>
            )}
            {showAdminResponse.data && showAdminResponse.data.length === 0 && (
              <Text
                variant="paragraph"
                color="gray.400"
                textAlign="center"
                mt="6"
              >
                No Show Staff
              </Text>
            )}
            {showAdminResponse.data &&
              showAdminResponse.data.map(({ id, name }) => {
                const elementId = `show-admin-${id}`;

                return (
                  <ListItem aria-labelledby={elementId} key={id}>
                    <Flex align="center" justify="space-between">
                      <Text id={elementId} variant="paragraph">
                        {name}
                      </Text>
                      {(me && me.id) !== id && (
                        <IconButton
                          aria-label={`Delete ${name}`}
                          icon="close"
                          variant="link"
                          size="md"
                          color="gray.600"
                          onClick={() => openRemoveModal(id)}
                        />
                      )}
                    </Flex>
                  </ListItem>
                );
              })}
          </List>

          {// You can only add show staff in these 3 states, otherwise hide the button
          (['published', 'unpublished', 'approved'].includes(showStatus) ||
            (isSuperAdmin && showStatus !== 'declined')) && (
            <Button
              leftIcon={AddIcon}
              variantColor="green"
              variant="link"
              onClick={addStaffModal.onOpen}
            >
              add show staff
            </Button>
          )}
        </SectionContent>
      </SectionCard>
    </>
  );
};
ShowStaffPanel.propTypes = {
  showStatus: PropTypes.string,
};

export default ShowStaffPanel;
