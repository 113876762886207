import React from 'react';
import { Link as ChakraLink } from '@chakra-ui/core';
import { Link as RrLink } from 'react-router-dom';

const Link = props => {
  return (
    <ChakraLink
      textDecoration="underline"
      color="primary.400"
      as={RrLink}
      {...props}
    />
  );
};

export default Link;
