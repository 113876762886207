import React from 'react';
import { Flex, Box } from '@chakra-ui/core';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { useMutation } from 'react-query';
import { useLocation, useHistory } from 'react-router';
import queryString from 'query-string';

import { resetPassword } from 'services/API/auth';

import CardPage from 'components/CardPage';
import TermsConditionsPrivacyPolicy from 'components/TermsConditionsPrivacyPolicy';
import Button from 'components/Button';
import Text from 'components/Text';
import useToast from 'Hooks/useToast';
import {
  newPasswordSchema,
  passwordConfirmationSchema,
} from 'Utilities/validators';
import NewPasswordFields from 'components/NewPasswordFields';

const ResetPassword = () => {
  const [mutate] = useMutation(resetPassword);
  const location = useLocation();
  const toast = useToast();
  const history = useHistory();
  const { email, token } = queryString.parse(location.search);

  return (
    <Formik
      initialValues={{
        password: '',
        password_confirmation: '',
      }}
      validationSchema={yup.object().shape({
        password: newPasswordSchema,
        password_confirmation: passwordConfirmationSchema,
      })}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await mutate({ ...values, token, email });
          history.push('/login');
          toast({
            title: 'Password Changed Successfully',
            description:
              'To sign in to Ringside, return to the mobile app or log in here.',
            status: 'success',
            isClosable: true,
          });
        } catch (e) {
          if (e.status === 400) {
            history.push('/forgot-password');
            toast({
              title: 'Password Reset Token is Expired',
              description: 'Submit a new password reset request to try again',
              status: 'error',
              isClosable: true,
            });
          } else {
            toast({
              title: `${e.status}: An unknown error has occurred`,
              description: e.error && e.error.message,
              status: 'error',
              isClosable: true,
            });
          }
          console.error(e);
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting }) => {
        return (
          <CardPage mb="10">
            <Flex direction="column" align="center" w="100%" px="10">
              <Text variant="paragraph" color="gray.400" mb="6">
                Please enter a new password.
              </Text>
              <Box
                as={Form}
                alignSelf="stretch"
                d="flex"
                flexDirection="column"
                alignItems="center"
              >
                <NewPasswordFields passwordProps={{ label: 'New Password' }} />

                <Button type="submit" isLoading={isSubmitting} mb="32" mt="6">
                  reset password
                </Button>
              </Box>

              <TermsConditionsPrivacyPolicy mt="auto" />
            </Flex>
          </CardPage>
        );
      }}
    </Formik>
  );
};

export default ResetPassword;
