/* @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import css from '@styled-system/css';
import PropTypes from 'prop-types';
import {
  Box,
  Flex,
  PseudoBox,
  Image,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
} from '@chakra-ui/core';
import { useQuery } from 'react-query';
import { Link, NavLink as RNavLink } from 'react-router-dom';

import logo2 from 'Assets/logo2.svg';
import { fetchShowById } from 'services/API/shows';

import { useAuth } from 'components/AuthContext';
import Button from 'components/Button';
import Text from 'components/Text';
import { useParams } from 'react-router';

export const SideBarLink = props => {
  return (
    <RNavLink
      {...props}
      css={css({
        '& .box': {
          borderLeft: '12px solid',
          borderColor: 'transparent',
        },

        '&.active .box': {
          position: 'relative',
          zIndex: 1,
          borderColor: 'primary.500',
          bg: 'whiteAlpha.200',
        },
      })}
    >
      <PseudoBox
        className="box"
        width="100%"
        py="10px"
        pl="40px"
        _hover={{ bg: 'whiteAlpha.200' }}
      >
        <Text variant="h4" color="white">
          {props.pageName}
        </Text>
      </PseudoBox>
    </RNavLink>
  );
};

const SideBar = () => {
  const auth = useAuth();
  const { showId } = useParams();
  const { isLoading, error, data } = useQuery(
    ['shows', { id: showId }],
    fetchShowById
  );
  const isSuperAdmin = auth.me && auth.me.is_super_admin;
  const status = data && data.status;

  return (
    <Flex
      as="nav"
      bg="primary.700"
      direction="column"
      height="100vh"
      width="280px"
    >
      <Flex direction="column">
        <Box pt="56px" mb="100px" alignSelf="center">
          <Link to="/shows">
            <Image src={logo2} alt="Ringside Logo" />
          </Link>
        </Box>
        <Flex direction="column">
          <SideBarLink exact pageName="Show Info" to={`/shows/${showId}/`} />
          {(['published', 'unpublished'].includes(status) ||
            (isSuperAdmin && status !== 'declined')) && (
            <>
              <SideBarLink
                pageName="Registration"
                to={`/shows/${showId}/registration`}
              />
              <SideBarLink
                pageName="Place Classes"
                to={`/shows/${showId}/placement`}
              />
            </>
          )}
        </Flex>
      </Flex>
      <Flex height="100%" justify="center" alignItems="flex-end" mb="8">
        {auth.me && (
          <Menu>
            {({ isOpen }) => (
              <>
                <MenuButton
                  as={Button}
                  rounded="sm"
                  width="100%"
                  mx="8"
                  height="auto"
                  py="3"
                  color="lightGray"
                  bg="primary.700"
                  transition="all 0.2s"
                  _hover={{ bg: 'whiteAlpha.400' }}
                  _focus={{ bg: 'whiteAlpha.400' }}
                  _active={{ bg: 'whiteAlpha.400' }}
                  _expanded={{ bg: 'whiteAlpha.400' }}
                  display="flex"
                  justifyContent="space-between"
                >
                  <Text
                    variant="callout"
                    mr="30px"
                    whiteSpace="normal"
                    textAlign="left"
                    flex="1"
                  >
                    {auth.me.first_name} {auth.me.last_name}
                  </Text>
                  {!isOpen ? (
                    <Icon fontSize="25px" name="chevron-down" />
                  ) : (
                    <Icon fontSize="25px" name="chevron-up" />
                  )}
                </MenuButton>
                <MenuList rounded="sm" p="0">
                  <MenuItem
                    _hover={{ color: 'primary.500' }}
                    onClick={auth.logout}
                    d="flex"
                    py="2"
                  >
                    <Text
                      variant="paragraph"
                      alignSelf="flex-end"
                      w="100%"
                      textAlign="right"
                    >
                      Logout
                    </Text>
                  </MenuItem>
                </MenuList>
              </>
            )}
          </Menu>
        )}
      </Flex>
    </Flex>
  );
};

SideBarLink.propTypes = {
  uri: PropTypes.string,
  pageName: PropTypes.string,
};

export default SideBar;
