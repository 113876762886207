import React from 'react';
import { Link as ChakraLink } from '@chakra-ui/core';
import { Stack } from '@chakra-ui/core';

const TermsConditionsPrivacyPolicy = props => {
  return (
    <Stack isInline mb="16" {...props}>
      <ChakraLink
        textDecoration="underline"
        color="primary.500"
        href="https://www.termsfeed.com/terms-conditions/65d1ddd3c7a8274b0186964b0abdd416"
        isExternal
      >
        Terms &amp; Conditions
      </ChakraLink>
      <ChakraLink
        textDecoration="underline"
        color="primary.500"
        href="https://www.termsfeed.com/privacy-policy/15f8ae105bc10c232ed974b322e6af5c"
        isExternal
      >
        Privacy Policy
      </ChakraLink>
    </Stack>
  );
};

export default TermsConditionsPrivacyPolicy;
