/* This theme file can be used to create consistent spacing, colors, and fonts across the app.
   See the `styled-system` theme documentation for more information on the format.

   For more information on how to use the theme file see: https://styled-system.com/theme-specification
*/
import { theme } from '@chakra-ui/core';

const customTheme = {
  ...theme,
  colors: {
    ...theme.colors,
    primary: {
      50: '#eae9ff',
      100: '#c4bff7',
      200: '#9c96eb',
      300: '#756ce1',
      400: '#4e42d7',
      500: '#584DD9', // from figma, light
      600: '#271f94',
      700: '#32307E', // from figma, dark
      800: '#0f0c42',
      900: '#06031c',
    },
    yellow: {
      50: '#fff7de',
      100: '#f9e7b5',
      200: '#f4d68a',
      300: '#EFC559', // from figma
      400: '#ebb630',
      500: '#d19c17',
      600: '#a37a10',
      700: '#745708',
      800: '#473402',
      900: '#1a1100',
    },
    red: {
      50: '#ffe9dd',
      100: '#ffc3af',
      200: '#ff9d7e',
      300: '#ff764c',
      400: '#FF5A26', // from figma
      500: '#e63700',
      600: '#b42a00',
      700: '#811d00',
      800: '#4f0f00',
      900: '#210200',
    },
    green: {
      50: '#e4fce9',
      100: '#c1edcd',
      200: '#9de1af',
      300: '#77d390',
      400: '#51c772',
      500: '#3BB75D', // from figma
      600: '#2a8744',
      700: '#1b6030',
      800: '#0d3b1a',
      900: '#001503',
    },
    gray: {
      50: '#FAFBFC', // gray 2
      100: '#d8d8df',
      200: '#DDE2E5', // gray 3
      300: '#a4a4a9',
      400: '#8a8a8f', // gray 4
      500: '#707075',
      600: '#57575c',
      700: '#3e3e43',
      800: '#212429', // black
      900: '#0b0b16',
    },
    black1: '#212429',
    lightGray: '#DDE2E5',
    lightGrayOpacity: '#FAFBFC12',
    lightGrayThirtyFive: '#FAFBFC59',
    buttonGray: '#C8C7CC',
  },
  fonts: {
    ...theme.fonts,
    body: 'Roboto, sans-serif',
    condensed: `Roboto Condensed,Roboto, sans-serif`,
    heading: 'Roboto, sans-serif',
  },
  letterSpacings: {
    ...theme.letterSpacings,
    widest: '0.09em',
  },
  // sizes: {
  //   field: 32,
  //   page: 1280,
  //   0: 0,
  //   1: 2,
  //   2: 4,
  //   3: 8,
  //   4: 16,
  //   5: 32,
  //   6: 64,
  //   7: 128,
  //   8: 256,
  //   9: 512,
  // },
  // fontSizes: pipe(
  //   addAlias('body', 2),
  //   addAlias('header', 5)
  // )([12, 14, 16, 20, 24, 32]),
  /*
    Uncomment this to modify the scale. The scale below is default already.
    space: [0, 4, 8, 16, 32, 64, 128, 256, 512]

    To use the scale, provide the index of the desired value to style props on a `styled-system` component.
    e.x.:
    ```
    <Box padding={2}></Box>
    ```
    This results in 8px of padding being applied to the Box because 8 is at index 2 of the scale array.

    For more information see: https://styled-system.com/theme-specification#space
    */
};

export default customTheme;
