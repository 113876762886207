import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import { Flex, Box } from '@chakra-ui/core';
import { Link as RLink } from 'react-router-dom';

import { register, resendEmail } from 'services/API/auth';
import {
  newPasswordSchema,
  passwordConfirmationSchema,
} from 'Utilities/validators';

import Button from 'components/Button';
import TermsConditionsPrivacyPolicy from 'components/TermsConditionsPrivacyPolicy';
import TextInput from 'components/TextInput';
import CardPage from 'components/CardPage';
import Text from 'components/Text';
import StateSelectInput from 'components/StateSelectInput';
import NewPasswordFields from 'components/NewPasswordFields';
import useToast from 'Hooks/useToast';

const RegistrationValidationSchema = Yup.object().shape({
  first_name: Yup.string()
    .max(30, 'Less than 30 characters')
    .required('First name required'),
  last_name: Yup.string()
    .max(30, 'Less than 30 characters')
    .required('Last name required'),
  username: Yup.string()
    .max(30, 'Less than 30 characters')
    .required('Username required'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Email required'),
  password: newPasswordSchema,
  password_confirmation: passwordConfirmationSchema,
  city: Yup.string().required('City required'),
  state: Yup.object().required('State required'),
});

const CreateAccount = () => {
  const [registerMutate, { data: registerData }] = useMutation(register);
  const [resendEmailMutate, { isLoading: isResendLoading }] = useMutation(
    resendEmail
  );
  const toast = useToast();

  return (
    <Formik
      initialValues={{
        first_name: '',
        last_name: '',
        username: '',
        email: '',
        password: '',
        password_confirmation: '',
        city: '',
        state: '',
      }}
      validationSchema={RegistrationValidationSchema}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        try {
          const payload = { ...values, state: values.state.abbreviation };
          await registerMutate(payload);
        } catch (error) {
          if (error.status === 422) {
            const validationErrors = error.error.validation_messages;
            setErrors(validationErrors);
          } else {
            toast({
              title: 'Failed to Create Account',
              description: 'An unexpected error occurred',
              status: 'error',
              duration: null, // never dismiss automatically
              isClosable: true,
            });
          }
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting, isValid }) => {
        return (
          <CardPage>
            <Flex direction="column" alignItems="center">
              {registerData ? (
                <>
                  <Flex direction="column" width="80%">
                    <Text textAlign="center" color="gray.400">
                      <Box>
                        Thank You! Your account has been created and a
                        <br /> confirmation email has been sent to:
                      </Box>
                      <Box
                        fontWeight="bold"
                        my="6"
                        overflow="hidden"
                        textOverflow="ellipsis"
                      >
                        {registerData.email}
                      </Box>
                      <Box textAlign="center" color="gray.400">
                        Please visit your email to verify your account. If you
                        do not receive an email within 10 minutes, please check
                        your spam folder or click &quot;Resend Email.&quot;
                      </Box>
                    </Text>
                  </Flex>
                  <Button
                    isLoading={isResendLoading}
                    isDisabled={false}
                    variant="solid"
                    variantColor="primary"
                    textDecoration="none"
                    mt="8"
                    mb="40"
                    onClick={async () => {
                      try {
                        const payload = { email: registerData.email };
                        await resendEmailMutate(payload);
                        toast({
                          title: 'An email has been resent.',
                          description:
                            'Please visit your email to verify your account.',
                          status: 'success',
                          duration: null, // never dismiss automatically
                          isClosable: true,
                        });
                      } catch (error) {
                        console.error(error);
                        toast({
                          title: 'Something went wrong!',
                          description: 'An unexpected error occurred.',
                          status: 'error',
                          duration: null, // never dismiss automatically
                          isClosable: true,
                        });
                      }
                    }}
                  >
                    Resend Email
                  </Button>
                  <Flex direction="column" width="70%">
                    <Text textAlign="center" color="gray.400">
                      If the email above is incorrect, please click the button
                      below to create a new account.
                    </Text>
                    <Button
                      variant="ghost"
                      variantColor="primary"
                      as={RLink}
                      to="/create-account"
                      textDecoration="none"
                      my="4"
                    >
                      Create New Account
                    </Button>
                  </Flex>
                </>
              ) : (
                <>
                  <Form>
                    <Flex direction="column" alignItems="stretch">
                      <Flex>
                        <TextInput
                          name="first_name"
                          label="First Name"
                          type="text"
                        />
                        <TextInput
                          name="last_name"
                          label="Last Name"
                          type="text"
                        />
                      </Flex>

                      <Flex>
                        <TextInput
                          name="city"
                          label="City"
                          type="text"
                          flex="3"
                        />
                        <StateSelectInput
                          label="State"
                          name="state"
                          w="110px"
                        />
                      </Flex>

                      <TextInput name="username" label="Username" type="text" />

                      <TextInput name="email" label="Email" type="email" />

                      <NewPasswordFields />

                      <Button
                        isLoading={isSubmitting}
                        isDisabled={!isValid}
                        alignSelf="center"
                        type="submit"
                        mt="10"
                      >
                        Create Account
                      </Button>
                    </Flex>
                  </Form>
                  <Button
                    variant={registerData ? 'solid' : 'ghost'}
                    variantColor="primary"
                    as={RLink}
                    to="/login"
                    textDecoration="none"
                    my="4"
                  >
                    Return to login
                  </Button>
                </>
              )}
            </Flex>

            <TermsConditionsPrivacyPolicy alignSelf="center" />
          </CardPage>
        );
      }}
    </Formik>
  );
};

CreateAccount.propTypes = {
  isSubmitting: PropTypes.bool,
  isValid: PropTypes.bool,
};

export default CreateAccount;
