import moment from 'moment';

const formatDateRange = (start, end) => {
  const parsedStart = moment(start);
  const parsedEnd = moment.utc(end);

  // start year formats
  const startYear = parsedStart.format('YYYY');
  const startMonth = parsedStart.format('MMM');
  const startDay = parsedStart.format('D');
  // end year formats
  const endYear = parsedEnd.format('YYYY');
  const endMonth = parsedEnd.format('MMM');
  const endDay = parsedEnd.format('D');

  if (startYear === endYear) {
    if (startMonth === endMonth) {
      if (startDay === endDay) {
        // Would cause a bug if the dates were exactly 1 year apart (e.x. Jan 15 2019 - Jan 15 2020 would be rendered as Jan 15 2019)
        // This won't happen given how this function is used
        return `${startMonth} ${startDay} ${startYear}`;
      }
      return `${startMonth} ${startDay}-${endDay} ${startYear}`;
    } else {
      return `${startMonth} ${startDay} - ${endMonth} ${endDay} ${endYear}`;
    }
  } else {
    return `${startMonth} ${startDay} ${startYear} - ${endMonth} ${endDay} ${endYear}`;
  }
};

export default formatDateRange;
