import React from 'react';
import styled from '@emotion/styled';
import { typography, space, color, layout } from 'styled-system';
import { Button as ChakraButton } from '@chakra-ui/core';
import styledSystemPropTypes from '@styled-system/prop-types';
import { variant } from 'styled-system';

const Button = React.forwardRef((props, ref) => {
  return (
    <ChakraButton
      fontFamily="condensed"
      letterSpacing="widest"
      fontWeight="bold"
      variant="solid"
      px="8"
      variantColor="primary"
      rounded="sm"
      textTransform="uppercase"
      fontSize="lg"
      size="lg"
      ref={ref}
      {...props}
    />
  );
});
Button.displayName = 'Button';

Button.propTypes = {};

export default Button;
