import React from 'react';
import PropTypes from 'prop-types';
import { find, prop } from 'ramda';
import { useTheme } from '@chakra-ui/core';
import Select from 'react-select';
import { getIn, useFormikContext } from 'formik';

import FieldWrapper from 'components/FieldWrapper';
import Text from 'components/Text';

const SelectInput = ({
  component: Component = Select,
  label,
  styles,
  options,
  name,
  helperText,
  containerProps,
  getOptionValue = prop('value'),
  ...props
}) => {
  const { setFieldValue, errors, touched, values } = useFormikContext();
  const meta = { error: getIn(errors, name), touched: getIn(touched, name) };
  const value = getIn(values, name);
  const { colors, radii } = useTheme();

  const onChange = newValue => {
    setFieldValue(name, newValue);
  };

  return (
    <FieldWrapper
      meta={meta}
      label={label}
      helperText={helperText}
      containerProps={containerProps}
    >
      <Text as="div" variant="paragraph">
        <Component
          value={
            typeof value === 'object'
              ? value
              : find(option => getOptionValue(option) === value, options)
          }
          onChange={onChange}
          styles={{
            ...styles,
            indicatorSeparator: () => ({
              visibility: 'hidden',
            }),
            control: provided => ({
              ...provided,
              fontWeight: 400,
              boxShadow:
                meta.error && meta.touched
                  ? `0 0 0 2px ${colors.red[400]}`
                  : provided.boxShadow,
            }),
            option: provided => ({
              ...provided,
              fontWeight: 400,
            }),
          }}
          getOptionValue={getOptionValue}
          theme={theme => {
            return {
              ...theme,
              borderRadius: radii.md,
              spacing: {
                ...theme.spacing,
                controlHeight: 40,
              },
              colors: {
                primary: colors.primary[500],
                primary75: colors.primary[400],
                primary50: colors.primary[200],
                primary25: colors.primary[100],
                danger: colors.red[400],
                dangerLight: colors.red[200],
                neutral0: colors.white,
                neutral5: colors.gray[50],
                neutral10: colors.gray[100],
                neutral20: colors.gray[200],
                neutral30: colors.gray[300],
                neutral40: colors.gray[400],
                neutral50: colors.gray[500],
                neutral60: colors.gray[600],
                neutral70: colors.gray[700],
                neutral80: colors.gray[800],
                neutral90: colors.gray[900],
              },
            };
          }}
          options={options}
          {...props}
        />
      </Text>
    </FieldWrapper>
  );
};
SelectInput.propTypes = {
  label: PropTypes.node,
  component: PropTypes.elementType,
  styles: PropTypes.object,
  options: PropTypes.array,
  name: PropTypes.string,
  helperText: PropTypes.string,
  containerProps: PropTypes.object,
  getOptionValue: PropTypes.func,
};

export default SelectInput;

/*


*/
