import * as yup from 'yup';

export const newPasswordSchema = yup
  .string()
  .min(8, 'Password must have at least 8 characters')
  .max(64, 'Password cannot be longer than 64 characters')
  .matches(/[a-z]/i, 'Password must contain at least one letter')
  .matches(/[0-9]/i, 'Password must contain at least one number')
  .required('Password is required');

export const passwordConfirmationSchema = yup
  .string()
  .oneOf([yup.ref('password'), null], 'Passwords must match')
  .required('Password confirmation required');

export const phoneNumberValidator = yup
  .string()
  .test(
    'is-valid-phone-number',
    'Phone number must have 10 numeric digits',
    value => {
      if (!value) return false;
      return value.replace(/\D/g, '').length === 10;
    }
  );
