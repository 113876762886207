/* eslint-disable react/prop-types */
import React from 'react';
import {
  FormLabel,
  FormErrorMessage,
  FormControl,
  FormHelperText,
} from '@chakra-ui/core';
import Text from 'components/Text';
import PropTypes from 'prop-types';

const FieldWrapper = ({
  helperText,
  label,
  containerProps,
  meta,
  disableErrors,
  children,
}) => {
  return (
    <FormControl
      my={3}
      width="100%"
      isInvalid={!!(meta.touched && meta.error) && !disableErrors}
      {...containerProps}
    >
      <FormLabel color="gray.800" display="block" width="100%">
        <Text variant="formLabel">{label}</Text>
        {children}
      </FormLabel>
      {helperText && <FormHelperText mt="1">{helperText}</FormHelperText>}
      {/* If the error is a boolean `true`, then we don't want to show the error message */}
      {meta.error !== true && (
        <FormErrorMessage color="red.400" fontSize="md">
          {meta.error}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};

FieldWrapper.propTypes = {
  label: PropTypes.string,
  //props for styling the container of what is being wrapped.
  containerProps: PropTypes.object,
  meta: PropTypes.shape({ touched: PropTypes.any, error: PropTypes.any }),
  children: PropTypes.node,
  helperText: PropTypes.node,
};

export default FieldWrapper;
