import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@chakra-ui/core';
import { useQuery } from 'react-query';
import { searchUsers } from 'services/API/users';

import SelectInput from 'components/SelectInput';

const UserSelectInput = ({
  name,
  label,
  helperText,
  excludeIds,
  isLoading,
  ...props
}) => {
  const [search, setSearch] = useState('');
  const allUsers = useQuery(['users', { search }], searchUsers);

  const formatOption = ({ first_name, last_name, city, state, id }) => ({
    value: id,
    label: `${first_name} ${last_name} (${city}, ${state})`,
  });
  const options = allUsers.data ? allUsers.data : [];
  const optionsFiltered = excludeIds
    ? options
        .filter(e => {
          return !excludeIds.some(f => {
            return e.id === f.id;
          });
        })
        .map(formatOption)
    : [];

  return (
    <Box {...props}>
      <SelectInput
        onInputChange={setSearch}
        options={optionsFiltered || []}
        isLoading={allUsers.isLoading || isLoading}
        name={name}
        label={label}
        helperText={helperText}
      />
    </Box>
  );
};
UserSelectInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.node,
  helperText: PropTypes.string,
  isLoading: PropTypes.bool,
  excludeIds: PropTypes.node,
};

export default UserSelectInput;
