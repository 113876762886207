import React from 'react';
import PropTypes from 'prop-types';
import { Link as RLink } from 'react-router-dom';
import {
  useToast,
  AlertDialog,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogHeader,
  AlertDialogFooter,
  AlertDialogBody,
  AlertDialogCloseButton,
} from '@chakra-ui/core';

import Button from 'components/Button';
import Text from 'components/Text';

const CancellationModal = ({
  isOpen,
  onClose,
  body,
  title,
  buttonText,
  buttonColor,
  redirect,
}) => {
  const toast = useToast();

  const handleClick = () => {
    onClose();
    toast({
      title: 'The Show Request has been Canceled',
      description: 'Show Admins will no longer see your request.',
      status: 'success',
      duration: 8000,
      isClosable: true,
    });
  };

  return (
    <AlertDialog isOpen={isOpen} onClose={onClose}>
      <AlertDialogOverlay />
      <AlertDialogContent px={4} py={5} maxW="660px" h="314px">
        <AlertDialogHeader>
          <Text variant="h1">{title}</Text>
        </AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>{body}</AlertDialogBody>

        <AlertDialogFooter>
          <Button
            onClick={handleClick}
            as={RLink}
            to={redirect}
            minW="189px"
            variantColor={buttonColor}
          >
            {buttonText}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

CancellationModal.propTypes = {
  body: PropTypes.string,
  title: PropTypes.string,
  buttonText: PropTypes.string,
  buttonColor: PropTypes.string,
  redirect: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default CancellationModal;
