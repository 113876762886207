import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Box, Spinner } from '@chakra-ui/core';
import { reject, find, is } from 'ramda';

import Text from 'components/Text';

export const SectionCard = ({ children, isLoading, ...props }) => {
  return (
    <Flex
      direction="column"
      as="section"
      border="1px"
      borderColor="gray.200"
      bg="white"
      {...props}
    >
      {React.Children.map(children, child =>
        React.cloneElement(child, { isLoading })
      )}
    </Flex>
  );
};
SectionCard.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool,
};

export const SectionContent = ({ children, isLoading, ...props }) => {
  return (
    <Box px="12" py="4" flex="1" {...props}>
      {isLoading ? (
        <Flex height="100%" justify="center">
          <Box pt="16">
            <Spinner color="primary.500" />
          </Box>
        </Flex>
      ) : (
        children
      )}
    </Box>
  );
};
SectionContent.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool,
};

export const SectionHeader = ({ children, ...props }) => {
  const childArray = React.Children.toArray(children);
  const isRightElement = element =>
    is(Object, element) && element.type === SectionRightElement;
  const rightElement = find(isRightElement)(childArray);

  return (
    <Text as="div" variant="formLabel">
      <Flex
        height="50px"
        py="2"
        px="6"
        borderBottom="1px"
        borderColor="inherit"
        align="center"
        {...props}
      >
        {reject(isRightElement)(children)}
        {rightElement}
      </Flex>
    </Text>
  );
};
SectionHeader.propTypes = {
  children: PropTypes.node,
};

export const SectionRightElement = ({ children, ...props }) => {
  return <Box ml="auto">{children}</Box>;
};
SectionRightElement.propTypes = {
  children: PropTypes.node,
};

export default SectionCard;
