import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { keyframes } from '@emotion/core';
import styled from '@emotion/styled';

import { useAuth } from 'components/AuthContext';
import { Spinner, Flex } from '@chakra-ui/core';

const fadeInDelay = keyframes({
  from: {
    opacity: 0,
  },
  to: {
    opacity: 1,
  },
});

const FadeIn = styled.div`
  animation: ${fadeInDelay} 0.3s ease-in-out ${({ delay }) => delay || '1s'}
    both;
`;

const PrivateRoute = props => {
  const auth = useAuth();
  if (auth.isLoading)
    return (
      <FadeIn>
        <Flex direction="column" align="center" justify="center" h="100vh">
          <Spinner color="primary.500" mb="4" />
        </Flex>
      </FadeIn>
    );
  return auth.isLoggedIn ? <Route {...props} /> : <Redirect to="/login" />;
};

PrivateRoute.propTypes = {
  ...Route.propTypes,
};

export default PrivateRoute;
