import React from 'react';
import { Flex } from '@chakra-ui/core';

import Text from 'components/Text';
import CardPage from 'components/CardPage';

const NotFound = () => {
  return (
    <CardPage>
      <Flex direction="column" align="center" pb="64">
        <Text variant="paragraph" color="gray.400">
          Oops! Something went wrong!
        </Text>
        <Text
          variant="h1"
          fontSize="6xl"
          color="primary.500"
          fontWeight="bold"
          fontFamily="condensed"
          letterSpacing="wider"
          mt="10"
        >
          404 ERROR
        </Text>
      </Flex>
    </CardPage>
  );
};

export default NotFound;
