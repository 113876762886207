import React from 'react';
import PropTypes from 'prop-types';
import {
  pathOr,
  flatten,
  chain,
  values,
  prop,
  length,
  nth,
  groupBy,
  pipe,
  toPairs,
  map,
} from 'ramda';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import {
  Flex,
  Box,
  Modal,
  Icon,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Divider,
} from '@chakra-ui/core';
import { MdFileDownload } from 'react-icons/md';

import FileUploadInput from 'components/FileUploadInput';
import Button from 'components/Button';
import Text from 'components/Text';
import useToast from 'Hooks/useToast';
import { bulkRegister, getRegistrationFileById } from 'services/API/shows';

const UploadModal = ({ isOpen, onClose }) => {
  const toast = useToast();
  const { showId } = useParams();
  const [mutate, uploadResult] = useMutation(bulkRegister, {
    refetchQueries: [['registrationFile', { showId }]],
  });

  const { isLoading, data } = useQuery(
    ['file', { showId }],
    getRegistrationFileById
  );

  const template = data && data.template;

  const hasValidationErrors =
    uploadResult.error && uploadResult.error.status === 422;

  const validationMessages = pipe(
    pathOr([], ['error', 'error', 'validation_messages']),
    toPairs,
    groupBy(([key]) => key.split('.')[0]),
    toPairs,
    map(([rowNumber, errorRows]) => ({
      rowNumber: parseInt(rowNumber),
      errors: flatten(map(nth(1), errorRows)),
    }))
  )(uploadResult);

  return (
    <Formik
      initialValues={{
        registration_file: '',
      }}
      validationSchema={Yup.object().shape({
        registration_file: Yup.mixed().required('File required'),
      })}
      onSubmit={async (values, { setSubmitting, setFieldError }) => {
        try {
          await mutate({ registration_file: values.registration_file, showId });
          toast({
            title: 'Excel File Added to Registration List',
            description: 'You can upload a new file and overwrite at any time.',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
          onClose();
        } catch (error) {
          // TODO this error is pretty lame, make it better
          setFieldError(
            'registration_file',
            'There was a problem importing the selected file. Please make sure you are using the provided Excel template.'
          );
          console.error();
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting }) => (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent px={4} py={5} maxW="791px" h="485px">
            <Form>
              <ModalHeader>
                <Flex align="baseline">
                  {hasValidationErrors ? (
                    <>
                      <Text variant="h1">Excel Upload Unsuccessful</Text>
                      <Text variant="paragraph" color="red.400" ml="auto">
                        {length(
                          chain(prop('errors'), values(validationMessages))
                        )}{' '}
                        Total Errors
                      </Text>
                    </>
                  ) : (
                    <Text variant="h1">Bulk Upload Registration</Text>
                  )}
                </Flex>
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                {hasValidationErrors ? (
                  <Box height="320px" overflowY="auto">
                    {map(
                      ({ rowNumber, errors }) => (
                        <Box key={rowNumber}>
                          <Text
                            variant="paragraph"
                            color="red.400"
                            fontWeight="bold"
                          >
                            Row {rowNumber + 1}
                          </Text>
                          {errors.map(error => (
                            <Text
                              variant="paragraph"
                              color="red.400"
                              key={error}
                            >
                              {error}
                            </Text>
                          ))}
                          <Divider />
                        </Box>
                      ),
                      validationMessages
                    )}
                  </Box>
                ) : (
                  <Flex direction="column">
                    <Text mb={4} variant="paragraph">
                      To bulk upload resgistration information, please use the
                      provided Excel template in order for us to accurately
                      display your information within Ringside.
                    </Text>
                    <Flex mb={4}>
                      <Button
                        as="a"
                        cursor="pointer"
                        href={template}
                        download
                        leftIcon={() => (
                          <Icon
                            as={MdFileDownload}
                            color="gray.400"
                            fontSize="24px"
                            mr="3"
                          />
                        )}
                        px={2}
                        variant="ghost"
                        isLoading={isLoading}
                      >
                        Download csv template
                      </Button>
                    </Flex>
                    <FileUploadInput
                      name="registration_file"
                      label="Attach Excel File"
                      // mime for excel spreadsheet (2007+)
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    />
                  </Flex>
                )}
              </ModalBody>

              {!hasValidationErrors && (
                <ModalFooter>
                  <Button isLoading={isSubmitting} type="submit">
                    Upload
                  </Button>
                </ModalFooter>
              )}
            </Form>
          </ModalContent>
        </Modal>
      )}
    </Formik>
  );
};

UploadModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default UploadModal;
