import React from 'react';
import { Box, Flex, Image, Spinner } from '@chakra-ui/core';
import PropTypes from 'prop-types';

import logo from 'Assets/logo.svg';

import Link from 'components/Link';

const CardPage = ({ isLoading, children, ...props }) => {
  return (
    <Box
      background="linear-gradient(238.34deg, #3D27C7 23.23%, #7048E8 103.14%)"
      minHeight="100vh"
    >
      <Flex
        alignItems="center"
        justifyContent="center"
        overflowY="auto"
        minHeight="100vh"
        py="20"
      >
        <Flex
          width="2xl"
          pt="16"
          px="24"
          bg="gray.50"
          rounded="sm"
          direction="column"
          {...props}
        >
          <Flex justifyContent="center" mb="10">
            <Link to="/login">
              <Image src={logo} alt="Ringside Logo" />
            </Link>
          </Flex>

          {isLoading ? (
            <Flex justify="center" align="center" mb="16">
              <Spinner color="primary.500" size="xl" />
            </Flex>
          ) : (
            children
          )}
        </Flex>
      </Flex>
    </Box>
  );
};

CardPage.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool,
};

export default CardPage;
