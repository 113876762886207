import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Box, Spinner } from '@chakra-ui/core';

import SideBar from 'components/SideBar';
import ShowSelectorDropDown from 'components/ShowSelectorDropDown';

const Page = ({ isLoading, children, isScrollable = true }) => {
  return (
    <Flex height="100vh">
      <SideBar />
      <Flex direction="column" flex="1" align="stretch">
        <Flex bg="white" justify="flex-end" px="16">
          <ShowSelectorDropDown />
        </Flex>
        <Box
          minHeight="0"
          flex="1"
          bg="gray.50"
          px="16"
          py="6"
          overflowY={isScrollable ? 'auto' : 'hidden'}
        >
          {isLoading ? (
            <Flex align="center" justify="center" height="100%">
              <Spinner size="xl" color="primary.500" />
            </Flex>
          ) : (
            <Box maxW="1366px">{children}</Box>
          )}
        </Box>
      </Flex>
    </Flex>
  );
};
Page.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  isScrollable: PropTypes.bool,
};

export default Page;
