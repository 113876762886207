import styled from '@emotion/styled';
import { typography, space, color } from 'styled-system';
import { Text as ChakraText } from '@chakra-ui/core';
import styledSystemPropTypes from '@styled-system/prop-types';
import { variant } from 'styled-system';

const Text = styled(ChakraText)(
  variant({
    variants: {
      h1: {
        fontSize: '34px',
        fontWeight: '500',
      },
      h2: {
        fontSize: '38px',
      },
      h3: {
        fontSize: '28px',
      },
      h4: {
        fontSize: '21px',
        fontWeight: 'medium',
        letterSpacing: '0.5px',
      },
      callout: {
        fontFamily: 'Roboto Condensed',
        fontWeight: 'bold',
        fontSize: '16px',
        textTransform: 'uppercase',
        letterSpacing: '0.09em',
      },
      loginButton: {
        fontFamily: 'Roboto Condensed',
        fontSize: '20px',
        fontWeight: 'bold',
        textTransform: 'uppercase',
      },
      paragraph: {
        fontSize: '16px',
        fontWeight: 'normal',
      },
      subText: {
        fontSize: '14px',
        color: '#8A8A8F',
      },
      formLabel: {
        fontWeight: '500',
        fontSize: '17px',
        letterSpacing: '0.005em',
      },
      small: {
        fontFamily: 'Roboto',
        fontSize: '14px',
      },
    },
  }),

  typography,
  space,
  color
);

Text.propTypes = {
  ...styledSystemPropTypes.typography,
  ...styledSystemPropTypes.space,
  ...styledSystemPropTypes.color,
};

export default Text;
