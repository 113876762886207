import React from 'react';
import { Flex, Icon, Spinner } from '@chakra-ui/core';
import { useQuery } from 'react-query';
import { getShows } from 'services/API/shows';
import { useParams } from 'react-router-dom';

import Text from 'components/Text';
import Button from 'components/Button';
import { Link as RLink } from 'react-router-dom';

import { Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/core';

const ShowSelectorDropDown = () => {
  const { showId } = useParams();
  const { data, isLoading, error } = useQuery('shows', getShows);
  const allShows = data ? data : [];

  if (isLoading) {
    return (
      <Flex my="4">
        <Spinner size="sm" color="primary.500" />
      </Flex>
    );
  } else if (error) {
    return (
      <Flex>
        <Text variant="formLabel" color="red.400" my="4">
          Shows failed to load
        </Text>
      </Flex>
    );
  } else if (allShows) {
    const currentShow = allShows.find(e => e.id == showId);

    return (
      <>
        {allShows.length >= 1 ? (
          <Menu>
            {({ isOpen }) => (
              <>
                <MenuButton
                  as={Button}
                  display="flex"
                  width="auto"
                  height="50px"
                  rounded="sm"
                  bg="white"
                  color="gray.800"
                  pr="0"
                  transition="all 0.2s"
                  _hover={{ bg: 'none' }}
                  _active={{ bg: 'none' }}
                  _expanded={{ color: 'primary.500' }}
                  _focus={{ outline: 0, boxShadow: 'none' }}
                >
                  <Text variant="formLabel" textTransform="none">
                    {currentShow && currentShow.show_name}
                  </Text>
                  {isOpen ? (
                    <Icon fontSize="25px" name="chevron-up" />
                  ) : (
                    <Icon fontSize="25px" name="chevron-down" />
                  )}
                </MenuButton>
                <MenuList mr="50px">
                  {allShows.map(show =>
                    show.id == showId ? null : (
                      <MenuItem
                        as={RLink}
                        to={`/shows/${show.id}`}
                        key={show.id}
                        pl="100px"
                        py="2"
                        justifyContent="flex-end"
                      >
                        {show.show_name}
                      </MenuItem>
                    )
                  )}
                  <MenuItem
                    justifyContent="flex-end"
                    as={RLink}
                    to={'/request-show'}
                  >
                    <Button variant="link" my="4">
                      request new show
                    </Button>
                  </MenuItem>
                </MenuList>
              </>
            )}
          </Menu>
        ) : (
          <Flex>
            <Button
              my="0px"
              variant="ghost"
              variantColor="primary"
              as={RLink}
              to="/request-show"
            >
              Request New Show
            </Button>
          </Flex>
        )}
      </>
    );
  }
};

export default ShowSelectorDropDown;
