import API from './API';
import { formatResponse } from './utils';

export const getStates = async () => {
  try {
    const { data } = await API.get('/metadata/states_provinces');
    return data.data;
  } catch ({ response }) {
    throw {
      error: response.data.error,
      status: response.status,
    };
  }
};

export const fetchAnimalBreeds = async () => {
  return formatResponse(API.get('/metadata/species_breeds'));
};
