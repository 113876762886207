import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@chakra-ui/core';
import { useField } from 'formik';

import Text from 'components/Text';

const CheckboxInput = ({ children, ...props }) => {
  const [field] = useField(props.name);
  return (
    <Checkbox
      variantColor="primary"
      size="lg"
      isChecked={field.value}
      {...field}
      {...props}
    >
      <Text variant="paragraph">{children}</Text>
    </Checkbox>
  );
};

CheckboxInput.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
};

export default CheckboxInput;
